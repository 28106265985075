import { useEffect, useState } from "react";
import { FloorPriceResponse, PetWithPrice } from "../../../common/types";
import { PetStatCard } from "../cards/PetStatCard";
import { PetTimerCard } from "../cards/PetTimerCard";
import { formatPrice, formatMinSec } from "../../helpers/helpers";

export function PetStore({
  topListings,
  potionStats,
  isPhone,
  remainingTime,
  walletAddress,
}: {
  topListings: PetWithPrice[];
  potionStats: FloorPriceResponse;
  isPhone: boolean;
  remainingTime: number;
  walletAddress: string;
}) {
  const [sortedPets, setSortedPets] = useState<PetWithPrice[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState<number | null>(null);
  const [isTimersSelected, setIsTimersSelected] = useState(false);
  const [floorPrice, setFloorPrice] = useState<number | null>(null);

  useEffect(() => {
    const uniqueListings = topListings.filter(
      (pet, index, self) => index === self.findIndex((p) => p.petId === pet.petId)
    );
    const sorted = [...uniqueListings].sort((a, b) => (b.priceInEth || 0) - (a.priceInEth || 0));
    setSortedPets(sorted);
  }, [topListings]);

  useEffect(() => {
    if (potionStats && potionStats.floor_price) {
      setFloorPrice(potionStats.floor_price);
    }
  }, [potionStats]);

  const handleTouchStart = (e: React.TouchEvent) => {
    if (isPhone) setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (isPhone && startX !== null) {
      const diffX = startX - e.touches[0].clientX;

      if (diffX > 50) {
        handleNext();
        setStartX(null);
      }

      if (diffX < -50) {
        handlePrev();
        setStartX(null);
      }
    }
  };

  const handleTouchEnd = () => {
    if (isPhone) setStartX(null);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : sortedPets.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < sortedPets.length - 1 ? prevIndex + 1 : 0));
  };

  const currentPet = sortedPets[currentIndex];

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex justify-center mr-6">
        <div className="justify-center w-72 flex flex-row ml-2 mt-4 text-lg rounded-lg border-[1.5px] border-brown px-2 py-1 bg-white mb-4 [box-shadow:inset_0_-2px_#342e2e]">
          <div className="flex flex-row"> Shop Refresh </div>
          <img src="/imgs/store.svg" alt="Next" className="h-4 w-4 ml-2 mt-[.38rem]" />
          <div className="flex flex-row ml-2 max-w-20"> {formatMinSec(remainingTime)} </div>
        </div>
      </div>

      <div className="flex flex-row justify-center">
        <div className="justify-center w-60 flex flex-row ml-8 mt-4 text-lg rounded-lg border-[1.5px] border-brown px-2 py-1 bg-white mb-4 [box-shadow:inset_0_-2px_#342e2e]">
          <img src="/imgs/paw.svg" alt="" className="mr-2 mt-[0.2rem] h-5 w-5" /> Adoption Center <img src="/imgs/paw.svg" alt="" className="ml-2 mt-[0.2rem] h-5 w-5" />
        </div>
        <div
          className={`relative h-6 w-10 mt-6 ml-2 rounded-full border-2 border-brown bg-white cursor-pointer ${
            isTimersSelected ? "bg-green" : ""
          }`}
          onClick={() => setIsTimersSelected((prev) => !prev)}
        >
          <div
            className={`h-4 w-4 m-[.135rem] rounded-full bg-brown ${
              isTimersSelected ? "transform translate-x-full" : ""
            }`}
          ></div>
        </div>
      </div>

      <div
        className="relative w-full max-w-lg mx-auto"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {sortedPets.length > 0 ? (
          <div className="flex items-center justify-center">
            {sortedPets.length > 1 && !isPhone && (
              <button
                className="absolute left-0 mb-20 z-10 bg-white p-2 rounded-full border-brown border-2 hover:bg-green"
                onClick={handlePrev}
              >
                <img src="/imgs/play.svg" alt="Previous" className="h-6 w-6 transform rotate-180" />
              </button>
            )}

            <div className="flex justify-center flex-col">
              {currentPet &&
                (isTimersSelected ? (
                  <PetTimerCard pet={currentPet} walletAddress={walletAddress} />
                ) : (
                  <PetStatCard pet={currentPet} />
                ))}
              <a
                href={`https://opensea.io/assets/base/0x5b51cf49cb48617084ef35e7c7d7a21914769ff1/${currentPet.petId}`}
                target="_blank"
                rel="noreferrer"
              >
                <div className="justify-center w-60 flex flex-row ml-[4.2rem] mt-4 text-lg rounded-lg border-[1.5px] border-brown px-2 py-1 bg-white mb-4 [box-shadow:inset_0_-2px_#342e2e] hover:bg-green">
                  Adopt <img src="/imgs/paw.svg" alt="" className="ml-2 mr-2 mt-[0.2rem] h-5 w-5" />
                  {currentPet?.priceInEth ? formatPrice(currentPet.priceInEth) : "N/A"} ETH
                </div>
              </a>
            </div>

            {sortedPets.length > 1 && !isPhone && (
              <button
                className="absolute right-0 mb-20 z-10 bg-white p-2 rounded-full border-brown border-2 hover:bg-green"
                onClick={handleNext}
              >
                <img src="/imgs/play.svg" alt="" className="h-6 w-6" />
              </button>
            )}
          </div>
        ) : (
          <div className="flex items-center mr-4 justify-center">
            <div className="text-md text-brown rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[16.5rem]">
              <img src="/imgs/fp_penguin_idle.png" alt=" " className="h-32 w-36 ml-14" />
              <div className="text-md text-center mb-2"> No pets up for adoption </div>
            </div>
          </div>
        )}
      </div>

      <div className="mt-8 border-b-2 border-brown"></div>

      <div className="flex flex-row justify-center">
        <div className="justify-center w-52 flex flex-row mr-4 mt-8 text-lg rounded-lg border-[1.5px] border-brown px-2 py-1 bg-white mb-4 [box-shadow:inset_0_-2px_#342e2e]">
          <img src="/imgs/flask.svg" alt="" className="mr-2 mt-[0.2rem] h-5 w-5" /> Potion Market <img src="/imgs/flask.svg" alt="" className="ml-2 mt-[0.2rem] h-5 w-5" />
        </div>
      </div>

      <div className="relative w-full max-w-lg mx-auto">
        <div className="flex items-center justify-center mr-4">
          <div className="flex mt-2 w-40 items-center justify-center rounded-lg border-[1.5px] border-brown px-2 py-1 bg-white mb-4 [box-shadow:inset_0_-2px_#342e2e]">
            <img src="/imgs/potion.png" alt="" className="h-32 w-36" />
          </div>
        </div>
        <div className="flex justify-center items-center mr-20 flex-col">
          <a
            href={`https://opensea.io/assets/base/0xd63b06d0a14a656731d338eb01c31dc9716c1d64/1`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="justify-center w-60 flex flex-row ml-[4.2rem] mt-2 text-lg rounded-lg border-[1.5px] border-brown px-2 py-1 bg-white [box-shadow:inset_0_-2px_#342e2e] hover:bg-green">
              Buy <img src="/imgs/flask.svg" alt="" className="ml-2 mr-2 mt-[0.3rem] h-4 w-4" /> {floorPrice ? `${formatPrice(floorPrice)} ETH` : " "}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
