import { useState } from "react";
import { Pet } from "../../../common/types";
import {
  MAX_MONSTERS,
  calcCarrotBalance,
  calculateStaked,
} from "../../helpers/helpers";

export function PetStatCard({ pet }: { pet: Pet }) {
 
  const [isNextGacha, setIsNextGacha] = useState(true);
  const [showStakeFp, setShowStakeFp] = useState(false);
  const toggleStakeFpDisplay = () => setShowStakeFp(!showStakeFp);

  const toggleGachaValue = () => setIsNextGacha(!isNextGacha);
  const passHolder = pet.passHolder;
  const petStatus = pet.status;
  const staker = pet.stakingPerks;
  const isZFren = pet.petType === "zfren";
  const isDMonkey = pet.petType === "dmonkey";
  const isGDog = pet.petType === "gdog";
  const isFPanda = pet.petType === "fpanda";
  const isEPepe = pet.petType === "epepe";

  let hibernating = false;
  if (petStatus === 5 ){
    hibernating = true;
  }

  return (
    <div className="mb-2 ml-4 mr-4 mt-2 flex h-[15.75rem] w-[21.5rem] flex-row rounded-lg border-2 border-brown bg-white p-3 [box-shadow:inset_0_-2px_#342e2e]">
      <div className="relative flex w-[54%] flex-col">
        {pet.encodedSvgUrl && (
          <a href={`https://frenpet.xyz/pet/${pet.id}`} target="_blank" rel="noreferrer">
            <div className="h-16 w-16 overflow-hidden rounded-md border-2 border-brown bg-green">
              <span className="absolute ml-[1.85rem] -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green px-2 py-[.1rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                lv. {pet.level}
              </span>
              <img
                src={pet.encodedSvgUrl}
                alt={"no pet img"}
                className={`object-cover ${
                  isZFren
                    ? "h-[3.5rem] w-[3.5rem] ml-[.1rem]"
                    : isDMonkey || isGDog || isFPanda
                    ? "h-[2.5rem] w-[2.5rem] ml-[.65rem] mt-2"
                    : ""
                } ${isEPepe ? "mix-blend-multiply" : ""}`}
              />
            </div>
          </a>
        )}
        {hibernating && (
          <span className="absolute ml-[.2rem] mt-[3rem]">
            <img src="/imgs/hibernated.svg" alt="badge" className="h-[.8rem] w-[.8rem]" />
          </span>
        )}
        {!hibernating && passHolder && (
          <span className="absolute ml-[.2rem] mt-[3rem]">
            <img src="/imgs/passBadge.png" alt="badge" className="h-[.8rem] w-[.8rem]" />
          </span>
        )}
        {!hibernating && !passHolder && staker && (
          <span className="absolute ml-[.3rem] mt-[3rem]">
            <img src="/imgs/frog.svg" alt="frog" className="h-[.8rem] w-[.8rem]" />
          </span>
        )}
        <div className="ml-1 mt-2 flex flex-col items-start space-y-4">
          <h2 className="text-md">id #{pet.id}</h2>
          <h2 className="text-md max-w-24 overflow-hidden text-ellipsis whitespace-nowrap">
            {pet.name}
          </h2>
          <h2 className="text-md flex max-w-24 overflow-hidden text-ellipsis whitespace-nowrap">
            <img src="/imgs/piggy-bank.svg" alt="" className="mr-1 mt-[.47rem] h-4 w-4" />
            <div className="mt-1"> {pet.score.toFixed(0)} </div>
          </h2>
          <h2 className="text-md flex max-w-24 overflow-hidden text-ellipsis whitespace-nowrap"  onClick={toggleGachaValue}>
            {pet.availableGacha > 0 && (
              <span className="absolute -translate-y-[30%] translate-x-[10%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                {pet.availableGacha}
              </span>
            )}
            <img src="/imgs/slot.svg" alt="" className="mr-1 mt-[.47rem] h-4 w-4" />
            <div className="mt-1"> {isNextGacha ?  pet.gachaEfficiency?.toFixed(2) : pet.nextGacha.toFixed(0)} </div>
          </h2>
        </div>
      </div>
      <div className="mr-3 border border-brown"></div>
      <div className="grid w-full grid-cols-2 gap-4">
        <div className="relative flex flex-col gap-[1.75rem]">
          <h2 className="flex items-center text-[.92rem] cursor-pointer" onClick={toggleStakeFpDisplay}>
            <img
              src={showStakeFp ? "/imgs/frog.svg" : "/imgs/ethereum.svg"}
              alt=""
              className="mr-2 h-5 w-5"
            />
            {showStakeFp
              ? `${calculateStaked(pet.fpPerSecond)}`
              : Number(pet.rewards) === 0
              ? 0
              : pet.rewards.toFixed(4)}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/hourglass.svg" alt="" className="mr-2 h-5 w-5 scale-x-[-1] transform" />
            <div>{pet.petAgeInDays}</div>
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/carrot.svg" alt="" className="mr-2 h-5 w-5" />
            {calcCarrotBalance(pet.carrotsBalance, pet.carrotsTimestamp).toFixed(2)}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/compass.svg" alt="explorer points" className="mr-2 h-5 w-5" />
            {pet.explorerPoints ?? 0}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/star.svg" alt="" className="mr-2 h-5 w-5" />
            {pet.stars}
          </h2>
        </div>
        <div className="relative flex flex-col gap-[1.7rem]">
        <h2 className="flex items-center text-[.92rem]">
          {pet.atkLevel > -1 && (
              <span className="absolute z-30 translate-y-[70%] -translate-x-[70%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                lv. {pet.atkLevel}
              </span>
            )}
            <img src="/imgs/sword.svg" alt="" className="mr-1 h-6 w-6 scale-x-[-1] transform" />
            {pet.attackPoints}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
          {pet.defLevel > -1 && (
              <span className="absolute translate-y-[65%] -translate-x-[70%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                lv. {pet.defLevel}
              </span>
            )}
            <img src="/imgs/shield.svg" alt="" className="mr-2 h-5 w-5" />
            {pet.defensePoints}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
          {pet.winsAvailable > 0 && (
              <span className="absolute -translate-y-[85%] translate-x-[25%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                {pet.winsAvailable}
              </span>
            )}
            <img src="/imgs/win.svg" alt="" className="mr-2 h-5 w-5" />
             {pet.petWins}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/shield-solid.svg" alt="" className="mr-2 ml-1 h-[1.15rem] w-[1.15rem]" />
            {pet.shieldCount}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/pokeball.svg" alt="" className="mr-1 h-6 w-6" />
            {pet.monstersCaught} / {MAX_MONSTERS}
          </h2>
        </div>
      </div>
    </div>
  );
}
