import { HibernationEvent, BonkEvent, ActivityEvent } from "../../../common/types";
import { useState, useEffect } from 'react';

type ActivityFeedProps = {
  hibernatedData: HibernationEvent[];
  bonkData: BonkEvent[];
};

export const ActivityFeed: React.FC<ActivityFeedProps> = ({ hibernatedData, bonkData }) => {
  const maxDisplay = 100;
  const [activityFeed, setActivityFeed] = useState<ActivityEvent[]>([]);

  useEffect(() => {
    const combinedData: ActivityEvent[] = [
      ...hibernatedData,
      ...bonkData
    ];

    setActivityFeed(prevFeed => {
      const newFeed = [...combinedData, ...prevFeed];

      const uniqueFeed = new Map<string, ActivityEvent>();

      for (const entry of newFeed) {
        const key = `${entry.winnerName}-${entry.loserName}-${entry.eventType}-${entry.timestamp}`;
        if (!uniqueFeed.has(key)) {
          uniqueFeed.set(key, entry);
        }
      }

      const sortedFeed = Array.from(uniqueFeed.values()).sort((a, b) => b.timestamp - a.timestamp);

      return sortedFeed.slice(0, maxDisplay);
    });
  }, [hibernatedData, bonkData]);

  const renderEventType = (eventType: string) => {
    switch (eventType) {
      case 'hibernated':
        return <img src="/imgs/hibernated.svg" alt="hibernated" className="ml-1 mr-1 h-4 w-4" />;
      case 'killed':
        return <img src="/imgs/skull.svg" alt="killed" className="ml-1 mr-1 h-4 w-4" />;
      case 'attack':
        return <img src="/imgs/sword.svg" alt="attack" className="mr-1 ml-1 h-6 w-6 scale-x-[-1] transform" />;
      default:
        return null;
    }
  };

  const renderArrow = (score: number) => {
    if (score >= 0) {
      return <img src="/imgs/down-arrow.svg" alt="" className="ml-1 mr-1 mt-[.1rem] h-4 w-4" />;
    } else {
      return <img src="/imgs/up-arrow.svg" alt="" className="ml-1 mr-1 mt-[.1rem] h-4 w-4" />;
    }
  };

  return (
    <div className="flex justify-center mr-4">
      <ul className="mb-2 flex h-screen w-[91%] flex-col">
        <li className="flex flex-col">
          <div className="flex flex-col">
            {activityFeed.map((data, index) => (
              <div
                className="justify-left mb-2 mt-2 text-sm flex w-max py-1 px-2 flex-row rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e]"
                key={index}
              >
                <div className="text-brown text-md">
                  {data.eventType === 'attack' ? (
                    <div className="flex flex-row">
                      {data.scoresWon >= 0
                        ? <>
                            <span className={`inline-block ${data.winnerName.length > 8 ? 'truncate max-w-[8ch]' : ''}`}>{data.winnerName}</span> {renderEventType(data.eventType)} <span className={`inline-block ${data.loserName.length > 8 ? 'truncate max-w-[8ch]' : ''}`}>{data.loserName}</span> {renderArrow(data.scoresWon)} {Math.abs(data.scoresWon)} {Math.abs(data.scoresWon) === 1 ? 'point' : 'points'}
                          </>
                        : <>
                            <span className={`inline-block ${data.loserName.length > 8 ? 'truncate max-w-[8ch]' : ''}`}>{data.loserName}</span> {renderEventType(data.eventType)} <span className={`inline-block ${data.winnerName.length > 8 ? 'truncate max-w-[8ch]' : ''}`}>{data.winnerName}</span> {renderArrow(data.scoresWon)} {Math.abs(data.scoresWon)} {Math.abs(data.scoresWon) === 1 ? 'point' : 'points'}
                          </>
                      }
                      {Math.abs(data.scoresWon) > 500 && Math.abs(data.scoresWon) <= 699 && (
                        <img src="/imgs/fire.svg" alt="fire" className="ml-1 h-5 w-5" />
                      )}
                      {Math.abs(data.scoresWon) > 700 && (
                        <img src="/imgs/whale.svg" alt="whale" className="ml-1 h-5 w-5" />
                      )}
                    </div>
                  ) : data.eventType === 'hibernated' ? (
                    <div className="flex items-center">
                       <span className={`inline-block ${data.winnerName.length > 8 ? 'truncate max-w-[8ch]' : ''}`}>{data.winnerName}</span> {renderEventType(data.eventType)} <span className={`inline-block ${data.loserName.length > 8 ? 'truncate max-w-[8ch]' : ''}`}>{data.loserName}</span>
                    </div>
                  ) : (
                    <div className="flex items-center">
                      <span className={`inline-block ${data.winnerName.length > 8 ? 'truncate max-w-[8ch]' : ''}`}>{data.winnerName}</span> {renderEventType(data.eventType)} <span className={`inline-block ${data.loserName.length > 8 ? 'truncate max-w-[8ch]' : ''}`}>{data.loserName}</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </li>
      </ul>
    </div>
  );
};
