import { PetRewards, Reward } from "../../../common/types";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  LineChart,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import { formatDayTime } from "../../helpers/helpers";
import { useState, useMemo } from "react";
import { colors, customLegend, customTooltip, secondsDict } from "../utils/ChartUtils";

const timestamps = {
  oneDay: 86400,
  oneWeek: 604800,
  oneMonth: 2592000,
};

function Button({
  text,
  selected,
  setSelected,
}: {
  text: string;
  selected: boolean;
  setSelected: (text: string) => void;
}) {
  return (
    <button
      className={`rounded-lg border-[1px] border-brown px-2 py-1 ${
        selected ? "bg-brown text-white" : "bg-white text-brown"
      }`}
      onClick={() => setSelected(text)}
    >
      {text}
    </button>
  );
}

function getAvgGrowth(rewards: Reward[], delta?: number, index?: number) {
  const latestReward = rewards[rewards.length - 1];
  let closestFirstReward;
  if (delta) {
    const firstTimestamp = latestReward.timestamp - delta;
    closestFirstReward = rewards.find((reward) => reward.timestamp >= firstTimestamp)!;
  } else if (index) {
    closestFirstReward = rewards[index];
  } else {
    closestFirstReward = rewards[0];
  }
  const deltaReward = latestReward.reward - closestFirstReward.reward;
  const deltaTimestamp = latestReward.timestamp - closestFirstReward.timestamp;
  return (deltaReward / deltaTimestamp) * 86400;
}

export function RewardChart({
  rewards,
  petRewards,
  showPets,
}: {
  rewards: Reward[];
  petRewards: PetRewards;
  showPets: boolean;
}) {
  const [selected, setSelected] = useState("1D");
  const [showTooltip, setShowTooltip] = useState(false);
  const [reference, setReference] = useState<number | undefined>(undefined);
  const firstPet = Object.keys(petRewards)[0];
  const [activePets, setActivePets] = useState<{ [key: string]: boolean }>(
    Object.keys(petRewards).reduce(
      (acc, key) => ({ ...acc, [key]: key === firstPet }),
      {}
    )
  );
  const isTouchDevice = "ontouchstart" in window;
  const toggleTooltip = () => setShowTooltip(!showTooltip);

  const getFilteredRewardsData = () => {
    if (showPets) {
      return Object.entries(petRewards).reduce((acc, [name, rewards]) => {
        acc[name] = rewards.filter(
          (reward) => reward.timestamp > Date.now() / 1000 - secondsDict[selected]
        );
        return acc;
      }, {} as PetRewards);
    } else {
      return {
        Total: rewards.filter(
          (reward) => reward.timestamp > Date.now() / 1000 - secondsDict[selected]
        ),
      };
    }
  };

  const filteredRewardsData = getFilteredRewardsData();

  const { maxReward, minReward, maxTimestamp, minTimestamp } = useMemo(() => {
    let maxReward = -Infinity;
    let minReward = Infinity;
    let maxTimestamp = -Infinity;
    let minTimestamp = Infinity;
    Object.entries(filteredRewardsData).forEach(([name, rewards]) => {
      if (showPets && !activePets[name]) return;
      rewards.forEach(({ reward, timestamp }) => {
        if (reward > maxReward) maxReward = reward;
        if (reward < minReward) minReward = reward;
        if (timestamp > maxTimestamp) maxTimestamp = timestamp;
        if (timestamp < minTimestamp) minTimestamp = timestamp;
      });
    });
    return { maxReward, minReward, maxTimestamp, minTimestamp };
  }, [filteredRewardsData, showPets, activePets]);

  return (
    <div className="my-2 ml-4 mr-4 flex w-[80%] flex-col items-start justify-center rounded-lg border-2 border-brown bg-white p-4 text-sm [box-shadow:inset_0_-2px_#342e2e]">
      <div className="mb-4 flex w-full content-between justify-between font-sans text-xl">
        <div
          onClick={toggleTooltip}
          className="flex cursor-default"
          onMouseEnter={() => !isTouchDevice && setShowTooltip(true)}
          onMouseLeave={() => !isTouchDevice && setShowTooltip(false)}
        >
          {showTooltip && (
            <div className="absolute z-10 ml-[5.5rem] w-max whitespace-nowrap rounded border-2 border-brown bg-white px-2 py-1 text-sm text-brown [box-shadow:inset_0_-2px_#342e2e]">
              <div className="flex">
                <span className="mr-1">24h •</span>
                <span>
                  {getAvgGrowth(rewards, timestamps["oneDay"]).toFixed(4)}
                </span>
              </div>
              <div className="mt-2 flex">
                <span className="mr-1">7D •</span>
                <span>
                  {getAvgGrowth(rewards, timestamps["oneWeek"]).toFixed(4)}
                </span>
              </div>
              <div className="mt-2 flex">
                <span className="mr-1">30D •</span>
                <span>
                  {getAvgGrowth(rewards, timestamps["oneMonth"]).toFixed(4)}
                </span>
              </div>
              <div className="mt-2 flex">
                <span className="mr-1">All Time •</span>
                <span>{rewards[rewards.length - 1].reward.toFixed(4)}</span>
              </div>
            </div>
          )}
          Rewards
        </div>
        <div className="space-x-1 font-sans text-sm">
          <Button text="1D" selected={selected === "1D"} setSelected={setSelected} />
          <Button text="7D" selected={selected === "7D"} setSelected={setSelected} />
          <Button text="1M" selected={selected === "1M"} setSelected={setSelected} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={filteredRewardsData.Total}
          onClick={(e) => {
            if (isTouchDevice) return;
            if (reference) {
              setReference(undefined);
            } else {
              setReference(Number(e?.activeLabel));
            }
          }}
          key="rewardChart"
        >
          <XAxis
            stroke="#342e2e"
            dataKey="timestamp"
            type="number"
            domain={
              selected === "1D"
              ? [
                () => minTimestamp - (maxTimestamp - minTimestamp) * .001,
                () => maxTimestamp
              ]
            : ['dataMin', 'dataMax']
            }
            tickFormatter={formatDayTime}
            allowDuplicatedCategory={false}
            tickCount={100}
          />
          <YAxis
            dataKey="reward"
            stroke="#342e2e"
            domain={[() => minReward, () => maxReward]}
            tickCount={100}
            tickFormatter={(tick) => tick.toFixed(4)}
          />
          <ReferenceLine
            x={reference}
            stroke="#342e2e"
            strokeDasharray="6 3"
            strokeWidth={2}
            key="rewardReferenceLine"
          />
          {Object.entries(filteredRewardsData).map(([name, rewards], index) => (
            <Line
              hide={showPets ? !activePets[name] : false}
              dataKey="reward"
              data={rewards.map((reward) => ({
                ...reward,
                timestamp: Math.floor(reward.timestamp / 100) * 100,
              }))}
              name={name}
              key={name}
              stroke={colors[index % colors.length]}
              type="monotone"
              dot={false}
            />
          ))}
          <Tooltip
            labelFormatter={formatDayTime}
            content={customTooltip(4, reference, filteredRewardsData)}
          />
          {showPets && <Legend content={customLegend(activePets, setActivePets)} />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
