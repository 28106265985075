import { MAX_MONSTERS } from "../../helpers/helpers";

export const Legend = () => (
    <div className="flex flex-col items-center justify-left h-full w-full">
      <div className="flex flex-col items-start justify-start mt-2 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
        <div className="flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Stat Bar</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2">
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/treasure.svg"
                alt=""
                className="h-5 w-5"
              />
              <div className="ml-[.02rem] mr-1">•</div>
              <p>Total ETH rewards</p>
            </li>
            <li className="flex items-center mb-2">
              <img
                src="/imgs/wallet.svg"
                alt=""
                className="ml-[.05rem] h-4 w-4"
              />
              <div className="ml-[.2rem] mr-1">•</div>
              <p>Wallet ETH balance</p>
            </li>
            <li className="flex items-center mb-2 ">
              <img
                src="/imgs/coin-bag.svg"
                alt=""
                className="h-5 w-5"
              />
              <div className="ml-[.02rem] mr-1">•</div>
              <p>Wallet pGold balance</p>
            </li>
            <li className="flex items-center mb-2">
                <img
                    src="/imgs/piggy-bank.svg"
                    alt=""
                    className="h-4 w-4"
               />
               <div className="ml-1 mr-1">•</div>
              <p>Total score</p>
            </li>
            <li className="flex items-center mb-2">
             <img
                src="/imgs/carrot.svg"
                alt=""
                className="h-4 w-4"
             />
             <div className="ml-1 mr-1">•</div>
             <p>Total carrot count</p>
            </li>
            <li className="flex items-center mb-2">
             <img src="/imgs/frog.svg" alt="" className="h-4 w-4" />
             <div className="ml-1 mr-1">•</div>
             <p>Wallet FP balance</p>
            </li>
            <li className="flex items-center mb-2">
             <img src="/imgs/frog.svg" alt="" className="h-4 w-4" />
             <div className="ml-1 mr-1">•</div>
             <p>Total FP claimable / Total fp staked</p>
            </li>
            <li className="flex items-center mb-2">
             <img
                src="/imgs/pokeball.svg"
                alt=""
                className="mt-[0.05rem] h-5 w-5"
             />
             <div className="mr-1">•</div>
             <p>Total monsters caught</p>
            </li>
            <li className="flex items-center mb-2">
            <img
                src="/imgs/star.svg"
                alt=""
                className="h-4 w-4"
            />
            <div className="ml-1 mr-1">•</div>
            <p>Total stars (hibernations | kills)</p>
            </li>
            <li className="flex items-center mb-2">
            <img
                src="/imgs/gas.svg"
                alt=""
                className="h-4 w-4"
            />
            <div className="ml-1 mr-1">•</div>
            <p>Current gas tick</p>
            </li>   
            <li className="flex items-center mb-2">
            <img
                src="/imgs/frog.svg"
                alt=""
                className="h-4 w-4"
            />
            <div className="ml-1 mr-1">•</div>
            <p>Current FP price</p>
            </li>            
          </ul>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start mt-4 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
        <div className="relative flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Timers View</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-2 ml-[.15rem]">
            <li className="flex items-center mb-2 ">
             <span className="absolute -translate-y-[5%] -translate-x-[28%] transform rounded-full border border-brown bg-green px-[.25rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                lv. 4
             </span>
             <div className="ml-6 mr-1">•</div>
             <p>Pet level</p>
            </li>
            <li className="flex items-center mb-2">
                <img
                    src="/imgs/piggy-bank.svg"
                    alt=""
                    className=" h-5 w-5"
               />
               <div className="ml-1 mr-1">•</div>
              <p>Pet score</p>
            </li>
            <li className="flex items-center mb-2">
            <img src="/imgs/slot.svg" alt="" className="ml-[.25rem] h-4 w-4" />
             <div className="ml-1 mr-1">•</div>
             <p>Points to next gacha</p>
            </li>
            <li className="flex items-center mb-2">
             <img src="/imgs/apple.svg" alt="" className="ml-[.15rem] h-4 w-4" />
             <div className="ml-[.35rem] mr-1"> •</div>
             <p>Time until pet starves</p>
            </li>
            <li className="flex items-center mb-2">
             <img
                src="/imgs/sword.svg"
                alt=""
                className="mt-[0.05rem] h-5 w-5 scale-x-[-1] transform"
             />
             <div className="ml-1 mr-1">•</div>
             <p>Time until next bonk</p>
            </li>
            <li className="flex items-center mb-2">
            <img
                src="/imgs/wheelspin.svg"
                alt=""
                className="h-5 w-5"
            />
            <div className="ml-1 mr-1">•</div>
            <p>Time until next wheel spin</p>
            </li>
            
            <li className="flex items-center mb-2">
            <img
                src="/imgs/shield-solid.svg"
                alt=""
                className="h-4 w-4 ml-[.15rem]"
            />
            <div className="ml-[.35rem] mr-1"> •</div>
            <p>Time until shield expires</p>
            </li>
            <li className="flex items-center mb-2">
            <img
                src="/imgs/weight.svg"
                alt=""
                className="h-4 w-4 ml-[.15rem]"
            />
            <div className="ml-[.35rem] mr-1"> •</div>
            <p>Time until training completes</p>
            </li>              
          </ul>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start mt-4 rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e] w-[21.5rem]">
        <div className="relative flex flex-col items-start justify-center w-full">
          <h2 className="text-lg font-semibold mb-1 p-1 mt-1">Stats View</h2>
          <hr className="w-full border-t-2 border-brown" />
          <ul className="w-full text-sm p-3">
            <li className="flex items-center mb-2 ">
            <span className="absolute -translate-y-[5%] -translate-x-[30%] transform rounded-full border border-brown bg-green px-[.25rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
               lv. 4
            </span>
            <div className="ml-6 mr-1">•</div>
            <p>Pet level</p>
            </li>
            <li className="flex items-center mb-2">
                <img
                    src="/imgs/piggy-bank.svg"
                    alt=""
                    className=" h-5 w-5"
               />
               <div className="ml-1 mr-1">•</div>
              <p>Pet score</p>
            </li>
            <li className="flex items-center mb-2">
            <img src="/imgs/slot.svg" alt="" className="ml-[.25rem] h-4 w-4" />
             <div className="ml-1 mr-1">•</div>
             <p>Gacha efficiency, avg expected of +2.5</p>
            </li>
            <li className="flex items-center mb-2">
             <img src="/imgs/ethereum.svg" alt="" className="ml-[.15rem] h-4 w-4" />
             <div className="ml-[.35rem] mr-1"> •</div>
             <p>Pet ETH rewards</p>
            </li>
            <li className="flex items-center mb-2">
             <img
                src="/imgs/hourglass.svg"
                alt=""
                className="h-4 w-4 ml-[.05rem] mr-[.05rem]"
             />
             <div className="ml-[.4rem] age1 mr-1">•</div>
             <p>Pet age in days alive</p>
            </li>
            <li className="flex items-center mb-2">
            <img
                src="/imgs/carrot.svg"
                alt=""
                className="h-4 w-4 ml-[.05rem]"
            />
            <div className="ml-[.45rem] mr-1"> •</div>
            <p>Pet carrot count</p>
            </li>   
            <li className="flex items-center mb-2">
            <img
                src="/imgs/passBadge.png"
                alt=""
                className="h-4 w-4 ml-[.05rem]"
            />
            <div className="ml-[.45rem] mr-1">•</div>
            <p>FP Monthly Pass</p>
            </li> 
            <li className="flex items-center mb-2">
            <img
                src="/imgs/frog.svg"
                alt=""
                className="h-4 w-4 ml-[.05rem]"
            />
            <div className="ml-[.45rem] mr-1">•</div>
            <p>FP claimable / FP staked</p>
            </li>
            <li className="flex items-center mb-2">
            <img
                src="/imgs/bolt.svg"
                alt=""
                className="mt-[0.05rem] ml-[.1rem] h-4 w-4"
             />
            <div className="ml-[.4rem] mr-1">•</div>
            <p>Bonk energy bar</p>
            </li>  
            <li className="flex items-center mb-2">
            <img
                src="/imgs/star.svg"
                alt=""
                className="h-4 w-4 ml-[.05rem]"
            />
            <div className="ml-[.45rem] mr-1">•</div>
            <p>Pet stars (hibernations | kills) </p>
            </li> 
            <li className="flex items-center mb-2">
            <img
                src="/imgs/sword.svg"
                alt=""
                className="mt-[0.05rem] h-5 w-5 scale-x-[-1] transform"
             />
            <div className="ml-[.26rem] mr-1">•</div>
            <p>Pet attack points </p>
            </li>  
            <li className="flex items-center mb-2">
            <img
                src="/imgs/shield.svg"
                alt=""
                className="mt-[0.05rem] h-4 w-4 "
             />
            <div className="ml-[.5rem] mr-1">•</div>
            <p>Pet defense points </p>
            </li>  
            <li className="flex items-center mb-2">
            <img
                src="/imgs/trophy.svg"
                alt=""
                className="mt-[0.05rem] h-4 w-4"
             />
            <div className="ml-[.5rem] mr-1">•</div>
            <p>Pet training wins | Pet total wins </p>
            </li>
            <li className="flex items-center mb-2">
            <img
                src="/imgs/pokeball.svg"
                alt=""
                className="mt-[0.05rem] h-5 w-5"
             />
            <div className="ml-[.26rem] mr-1">•</div>
            <p>Total monsters caught out of {MAX_MONSTERS}  </p>
            </li>
            <li className="flex items-center mb-2">
            <img
                src="/imgs/shield-solid.svg"
                alt=""
                className="mt-[0.05rem] ml-[.1rem] h-4 w-4"
             />
            <div className="ml-[.4rem] mr-1">•</div>
            <p>Shield owned count</p>
            </li>  
            <li className="flex items-center mb-2">
            <img src="/imgs/slot.svg" alt="" className="ml-[.15rem] h-4 w-4" />
            <span className="absolute -translate-y-[45%] translate-x-[50%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                1
            </span>
             <div className="ml-[.35rem] mr-1">•</div>
             <p>Pending gacha rolls</p>
            </li>
            <li className="flex items-center mb-2">
            <img src="/imgs/trophy.svg" alt="" className="ml-[.05rem] h-4 w-4" />
            <span className="absolute -translate-y-[45%] translate-x-[50%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                1
            </span>
             <div className="ml-[.43rem] mr-1">•</div>
             <p>Availble wins to use on training</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );