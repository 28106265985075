import { PGoldBalance, FpPrice, PGoldPrice } from "../../../common/types";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  LineChart,
  Tooltip,
} from "recharts";
import { formatDayTime, calculateTicks, formatCurrency } from "../../helpers/helpers";
import { useState, useMemo } from "react";
import { PGoldTooltip, secondsDict } from "../utils/ChartUtils";

function Button({
  text,
  selected,
  setSelected,
}: {
  text: string;
  selected: boolean;
  setSelected: (text: string) => void;
}) {
  return (
    <button
      className={`rounded-lg border-[1px] border-brown px-2 py-1 ${
        selected ? "bg-brown text-white" : "bg-white text-brown"
      }`}
      onClick={() => setSelected(text)}
    >
      {text}
    </button>
  );
}

interface CombinedData {
  timestamp: number;
  pgold_balance: number;
  pgold_price: number | null;
  fp_price: number | null;
}

export function PGoldBalanceChart({
  pGoldBalances,
  fpPrices,
  pGoldPrices,
}: {
  pGoldBalances: PGoldBalance[];
  fpPrices: FpPrice[];
  pGoldPrices: PGoldPrice[];
}) {
  const [selectedTimeRange, setSelectedTimeRange] = useState("1D");
  const [selectedCurrency, setSelectedCurrency] = useState("DEFAULT");
  const [isUSDToggled, setIsUSDToggled] = useState(false);

  function findClosestPrice<T extends { timestamp: number }>(
    pricesArray: T[],
    timestamp: number
  ): T | null {
    if (pricesArray.length === 0) {
      return null;
    }
    for (let i = pricesArray.length - 1; i >= 0; i--) {
      if (pricesArray[i].timestamp <= timestamp) {
        return pricesArray[i];
      }
    }
    return pricesArray[0];
  }

  const data: CombinedData[] = useMemo(() => {
    const filteredBalances = pGoldBalances.filter(
      (balance) =>
        balance.timestamp > Date.now() / 1000 - secondsDict[selectedTimeRange]
    );

    const combinedData: CombinedData[] = filteredBalances.map((balance) => {
      const pGoldPriceData = findClosestPrice(pGoldPrices, balance.timestamp);
      const fpPriceData = findClosestPrice(fpPrices, balance.timestamp);

      return {
        timestamp: balance.timestamp,
        pgold_balance: balance.pgold_balance,
        pgold_price: pGoldPriceData ? pGoldPriceData.pgold_price : null,
        fp_price: fpPriceData ? fpPriceData.fp_price : null,
      };
    });

    return combinedData;
  }, [pGoldBalances, pGoldPrices, fpPrices, selectedTimeRange]);

  const getBalanceInSelectedCurrency = (balanceData: CombinedData): number | null => {
    const { pgold_balance, pgold_price, fp_price } = balanceData;

    if (selectedCurrency === "USD") {
      if (pgold_price !== null && fp_price !== null) {
        return (1 / pgold_price) * fp_price * pgold_balance;
      } else {
        return null; 
      }
    }
    if (selectedCurrency === "FP") {
      if (pgold_price !== null) {
        return (1 / pgold_price) * pgold_balance;
      } else {
        return null; 
      }
    }
    return pgold_balance;
  };

  return (
    <div className="my-2 ml-4 mr-4 flex w-[80%] flex-col items-start justify-center rounded-lg border-2 border-brown bg-white p-4 text-sm [box-shadow:inset_0_-2px_#342e2e]">
      <div className="flex w-full justify-between">
        <div className="flex flex-row">
          <div className="mb-4 font-sans text-xl">$pGold</div>
          <div className="mb-4 ml-1 space-x-1 flex flex-row font-sans text-sm">
            <div className="ml-2 mr-2">
              <Button
                text="pGold"
                selected={selectedCurrency === "DEFAULT"}
                setSelected={() => setSelectedCurrency("DEFAULT")}
              />
            </div>
            <Button
              text={isUSDToggled ? "USD" : "FP"}
              selected={selectedCurrency === "FP" || selectedCurrency === "USD"}
              setSelected={() => {
                setSelectedCurrency("FP");
                setIsUSDToggled(false);
              }}
            />
            {(selectedCurrency === "FP" || selectedCurrency === "USD") && (
              <div className="flex flex-row items-center">
                <div
                  className={`relative h-6 w-10 ml-2 rounded-full border-2 border-brown bg-white cursor-pointer ${
                    isUSDToggled ? "bg-green" : ""
                  }`}
                  onClick={() => {
                    const chartToggle = !isUSDToggled;
                    setIsUSDToggled(chartToggle);
                    setSelectedCurrency(chartToggle ? "USD" : "FP");
                  }}
                >
                  <div
                    className={`h-4 w-4 m-[.135rem] rounded-full bg-brown ${
                      isUSDToggled ? "transform translate-x-full" : ""
                    }`}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mb-4 space-x-1 font-sans text-sm">
          <Button
            text="1D"
            selected={selectedTimeRange === "1D"}
            setSelected={setSelectedTimeRange}
          />
          <Button
            text="7D"
            selected={selectedTimeRange === "7D"}
            setSelected={setSelectedTimeRange}
          />
          {/*
          <Button
            text="1M"
            selected={selectedTimeRange === "1M"}
            setSelected={setSelectedTimeRange}
          />
          */}
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data} margin={{ left: 45 }}>
          <XAxis
            dataKey="timestamp"
            type="number"
            tickFormatter={formatDayTime}
            ticks={calculateTicks(data)}
            domain={["dataMin", "dataMax"]}
            stroke="#342e2e"
          />
          <YAxis
            dataKey={(balanceData) =>
              getBalanceInSelectedCurrency(balanceData)
            }
            stroke="#342e2e"
            domain={["auto", "auto"]}
            tickFormatter={formatCurrency}
          />
          <Line
            type="monotone"
            dataKey={(balanceData) =>
              getBalanceInSelectedCurrency(balanceData)
            }
            stroke="#342e2e"
            dot={false}
          />
          <Tooltip
            content={
              <PGoldTooltip
                selectedCurrency={selectedCurrency}
              />
            }
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
