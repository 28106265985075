import { useState } from "react";
import { Pet } from "../../../common/types";
import { formatTimerCardTime, getPassesIcon } from "../../helpers/helpers";
import { Meter } from "../utils/Meter";

export function PetPassCard({ pet, walletAddress }: { pet: Pet; walletAddress: string }) {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => setShowTooltip(!showTooltip);

  const statusIcon = getPassesIcon(
    pet.timeUntilStarving,
    pet.timeUntilWheel,
    pet.atkTrainingEndsTimestamp,
    pet.defTrainingEndsTimestamp,
    pet.shieldExpires
  );


  const timeUntilStarving = formatTimerCardTime(pet.timeUntilStarving, "starving");
  const timeUntilAttack = formatTimerCardTime(pet.timeUntilAttack, "attack");
  const availableBonks = pet.availableBonks;
  const petStatus = pet.status;
  const passUntil = formatTimerCardTime(pet.passUntil, "expiring");
  const isZFren = pet.petType === "zfren";
  const isDMonkey = pet.petType === "dmonkey";
  const isGDog = pet.petType === "gdog";
  const isFPanda = pet.petType === "fpanda";
  const isEPepe = pet.petType === "epepe";

  const hibernating = petStatus === 5;

  return (
    <div className="overflow-hidden mb-2 ml-4 mr-4 mt-2 flex h-[15rem] w-[21.5rem] flex-col rounded-lg border-2 border-brown bg-white [box-shadow:inset_0_-2px_#342e2e]">
      <div className="relative w-full h-full">
        <div
          className="h-[7rem] w-full bg-cover bg-center"
          style={{ backgroundImage: `url('/imgs/fpBkg.png')` }}
        >
          {statusIcon && (
                <span className="absolute mt-[3.2rem] ml-[12.7rem] flex -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green px-2 py-[.2rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                  <img
                    src={statusIcon}
                    alt=""
                    className={`h-4 w-4
                      ${statusIcon === "/imgs/weight.svg" ? "h-[.75rem] w-[.85rem] mb-[.1rem]" : ""}
                      ${statusIcon === "/imgs/wheelspin.svg" ? "scale-x-[-1] transform mb-[.1rem]" : ""}
                      ${statusIcon === "/imgs/apple.svg" ? "h-[.75rem] w-[.85rem] mb-[.1rem]" : ""}
                      ${statusIcon === "/imgs/red-apple.svg" ? "h-[.75rem] w-[.85rem] mb-[.1rem]" : ""}
                      ${statusIcon === "/imgs/shield-solid.svg" ? "h-[.75rem] w-[.75rem] mb-[.1rem]" : ""}
                    `}
                  />
                </span>
              )}
          <span className="absolute mt-4 -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green px-2 py-[.1rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
            lv. {pet.level}
          </span>
          <span className="absolute mt-[3.2rem] flex flex-row -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green pr-[.45rem] pl-[.2rem] py-[.1rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
           <img src="/imgs/sword.svg" alt="" className="h-4 w-4 scale-x-[-1] transform mb-[.05rem] mr-[.15rem]" />  {pet.attackPoints}
          </span>
          <span className="absolute mt-[5.5rem] flex flex-row -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green pr-[.4rem] pl-[.2rem] py-[.1rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
           <img src="/imgs/shield.svg" alt="" className="h-3 w-3 mt-[.1225rem] mr-[.175rem] ml-[.25rem]" />  {pet.defensePoints}
          </span>
          <span className="absolute flex flex-row mt-4 ml-[15.25rem] -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green pr-[.5rem] pl-[.47rem]  py-[.1rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
            <img src="/imgs/ethereum.svg" alt="" className="h-[.85rem] w-[.85rem] mt-[.02rem] mr-[.15rem]" /> {pet.rewards.toFixed(3)}
          </span>
          <span className="absolute flex flex-row mt-[3.2rem] ml-[15.53rem] -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green px-2 py-[.1rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
            <img src="/imgs/hourglass.svg" alt="" className="h-[.75rem] w-[.75rem] mt-[.1rem] mr-[.15rem]" /> {pet.petAgeInDays}
          </span>
          <span className="absolute flex flex-row mt-[5.5rem] ml-[15.25rem] -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green pr-[.5rem] pl-[.4rem] py-[.1rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
            <img src="/imgs/compass.svg" alt="" className="h-[.75rem] w-[.75rem] mt-[.1rem] mr-[.15rem]" /> {pet.explorerPoints}
          </span>
          <div className="flex h-full items-center justify-center">
            <a href={`https://frenpet.xyz/pet/${pet.id}`} target="_blank" rel="noreferrer">
              <div
                className={`${
                  isZFren
                    ? "ml-5 rounded-lg border-2 border-black bg-green"
                    : isEPepe
                    ? "rounded-lg border-2 border-black bg-green"
                    : ""
                }`}                
              >
                <img
                src={pet.encodedSvgUrl}
                alt={"no pet img"}
                style={isEPepe ? { mixBlendMode: "multiply" } : undefined}
                className={`object-cover ${
                  (isZFren || isEPepe)
                    ? "h-[4.25rem] w-[4.25rem]"
                    : (isDMonkey || isGDog || isFPanda)
                    ? "h-[3.5rem] w-[3.5rem] ml-5"
                    : "h-[6rem] w-[6rem] ml-5"
                }`}
                />

              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full border-b-4 border-brown"></div>
      <div className="relative mt-2 ml-9 mr-3 flex flex-row justify-between">
        <h2 className="max-w-[5.5rem] text-md flex items-center overflow-hidden text-ellipsis whitespace-nowrap">
          <img src="/imgs/piggy-bank.svg"alt="" className="h-4 w-4  mr-1"/>
          <div className="min-w-20">
            {pet.score?.toFixed(0)}
          </div>
        </h2>

        <h2 className="text-md mr-[1.75rem] flex items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap truncate">
          {pet.name.length > 11 ? `${pet.name.slice(0, 6)}...` : pet.name}
        </h2>
        
        <h2 className="text-md flex items-center overflow-hidden text-ellipsis whitespace-nowrap">
        {pet.availableGacha > 0 && (
              <span className="absolute -translate-y-[40%] translate-x-[15%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                {pet.availableGacha}
              </span>
            )}
          <img src="/imgs/slot.svg" alt="" className="mr-1 h-4 w-4" />
          <div>{pet.nextGacha.toFixed(0)}</div>
        </h2>
      </div>

      <div className="relative flex flex-col justify-start">
        <div className="flex flex-col h-full w-full p-2">
          {showTooltip && (
            <div className="absolute w-[8.3rem] top-[-122%] left-[5%] z-20 mt-2 rounded border-2 border-brown bg-white px-2 py-1 text-brown [box-shadow:inset_0_-2px_#342e2e]">
              <div className="flex">
                <img src="/imgs/skull.svg" alt="" className="ml-1 mr-1 mt-[0.22rem] h-4 w-4" />
                • {timeUntilStarving}
              </div>
              <div className="mt-2 flex">
                <img
                  src="/imgs/sword.svg"
                  alt=""
                  className="z-10 mt-[0.05rem] h-6 w-6 scale-x-[-1]"
                />
                • {timeUntilAttack}
              </div>
              <div className="mt-2 flex">
                <img src="/imgs/bolt.svg" alt="" className="z-10 mt-[0.05rem] h-5 w-5" />
                • {availableBonks} bonks
              </div>
              <div className="mt-2 flex">
                <img
                  src="/imgs/passBadge.png"
                  alt=""
                  className="z-10 mt-[0.05rem] mr-[.1rem] h-5 w-5"
                />
                • {passUntil}
              </div>
            </div>
          )}
          <Meter
            startSec={pet.lastAttackUsed}
            endSec={pet.timeUntilAttack}
            imgSrc="/imgs/sword.svg"
            imgClass="h-6 w-6 scale-x-[-1]"
            isDepleting={false}
            showTooltip={false}
            walletAddress={walletAddress}
            reverseTooltip={true}
            onClick={toggleTooltip}
          />
          <Meter
            availableBonks={pet.availableBonks}
            imgSrc="/imgs/bolt.svg"
            imgClass="h-5 w-5"
            walletAddress={walletAddress}
            showTooltip
          />
          <Meter
            passUntil={pet.passUntil}
            imgSrc="/imgs/passBadge.png"
            imgClass="h-5 w-5"
            walletAddress={walletAddress}
            showTooltip
            isDepleting={true}
          />
        </div>
      </div>
    </div>
  );
}
