import { BarChart, Bar, XAxis, YAxis, ResponsiveContainer, Cell, LabelList } from "recharts";
import { EmptyState } from "./EmptyState";
import { FpChart } from "../charts/FpChart";
import { PGoldChart } from "../charts/PGoldChart";
import { EthPrice, FpPrice, GasPrice, PGoldPrice, TreasuryBalance } from "../../../common/types";
import { GasChart } from "../charts/GasChart";
import { EthChart } from "../charts/EthChart";
import { TreasuryChart } from "../charts/TreasuryChart";

const COLORS = ["#342e2e", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF", "#FF4560", "#d46b2f", "#95cfec", "#342e2e"];

export function Stats({
  speciesCounts,
  walletAddress,
  fpPrices,
  pGoldPrices,
  isPhone,
  ethPrices,
  gasPrices,
  treasuryBalances
}: {
  speciesCounts: { species: string; count: number }[] | null;
  walletAddress: string;
  ethPrices: EthPrice[];
  pGoldPrices: PGoldPrice[];
  isPhone: boolean;
  gasPrices: GasPrice[];
  fpPrices: FpPrice[];
  treasuryBalances: TreasuryBalance[];
}) {
  const isLoading = !speciesCounts && !!walletAddress;
  if (isLoading) return <div><EmptyState isLoading={isLoading} isStats={true} /></div>;

  const data = Array.isArray(speciesCounts)
    ? speciesCounts
        .filter(entry => entry?.species && typeof entry.count === "number")
        .map(({ species, count }) => ({
          name: species.slice(1).charAt(0).toUpperCase() + species.slice(2),
          value: count,
        }))
        .sort((a, b) => b.value - a.value)
    : [];

  const totalPets = data.reduce((sum, pet) => sum + pet.value, 0);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex justify-center mr-6">
        <div className="justify-center w-72 flex flex-row ml-2 mt-4 text-lg rounded-lg border-[1.5px] border-brown px-2 py-1 bg-white mb-4 [box-shadow:inset_0_-2px_#342e2e]">
          <div className="flex flex-row">Pet Species</div>
          <img src="/imgs/paw.svg" alt="paw" className="h-4 w-4 ml-2 mt-[.38rem]" />
        </div>
      </div>
      <div className="flex flex-row justify-center">
        <div className="my-2 ml-4 mr-4 flex w-[80%] flex-col items-center justify-center rounded-lg border-2 border-brown bg-white p-4 text-sm [box-shadow:inset_0_-2px_#342e2e]">
          <div className="flex w-full justify-between">
            <div className="mb-4 font-sans text-xl">Pets Alive • {totalPets}</div>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data} layout="horizontal" margin={{ top: 20 }}>
              <XAxis dataKey="name" stroke="#342e2e" tick={false} />
              <YAxis stroke="#342e2e" />
              <Bar dataKey="value" fill="#342e2e">
                {data.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                <LabelList dataKey="value" position="top" />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
            {data.map((entry, index) => (
              <div key={index} className="flex items-center">
                <div
                  className="w-3 h-3 rounded-full mr-2"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                ></div>
                <span>{entry.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-8 border-b-2 border-brown"></div>
      <div className="flex flex-col justify-center">
        <div className="flex justify-center">
          <div className="justify-center w-72 flex flex-row ml-2 mt-4 text-lg rounded-lg border-[1.5px] border-brown px-2 py-1 bg-white mb-4 [box-shadow:inset_0_-2px_#342e2e]">
            <div className="flex flex-row">FP Charts</div>
            <img src="/imgs/passBadge.png" alt="" className="h-4 w-4 ml-2 mt-[.38rem]" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <FpChart fpPrices={fpPrices} />
          <PGoldChart pGoldPrices={pGoldPrices} fpPrice={fpPrices[fpPrices.length - 1]} isPhone={isPhone} />
          <TreasuryChart treasuryBalances={treasuryBalances} />
        </div>
      </div>
      <div className="mt-8 border-b-2 border-brown"></div>
      <div className="flex flex-col justify-center mr-6">
        <div className="flex justify-center">
          <div className="justify-center w-72 flex flex-row ml-2 mt-4 text-lg rounded-lg border-[1.5px] border-brown px-2 py-1 bg-white mb-4 [box-shadow:inset_0_-2px_#342e2e]">
            <div className="flex flex-row">Crypto Charts</div>
            <img src="/imgs/chart.svg" alt="" className="h-4 w-4 ml-2 mt-[.38rem]" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <GasChart gasPrices={gasPrices} />
          <EthChart ethPrices={ethPrices} />
        </div>
      </div>
    </div>
  );
}
