import { useState } from "react";
import { Pet } from "../../../common/types";
import {
  MAX_MONSTERS,
  OWNERS,
  calcCarrotBalance,
  calculateMonsterProgress,
  calculateStaked,
  getTopLeftIcon,
} from "../../helpers/helpers";

export function VerticalMeter({ value }: { value: number }) {
  const percentage = (value / 32) * 100;
  return (
    <div
      className={`relative h-[95%] w-2 rounded-lg border-2 border-brown ${percentage > 0 ? "bg-brown" : "bg-red"}`}
    >
      {value > 0 && (
        <div
          className={`absolute bottom-0 w-full rounded-lg border-t-[2px] border-t-brown bg-green`}
          style={{ height: `${percentage}%` }}
        />
      )}
    </div>
  );
}
export function PetStatOwnerCard({ pet, walletAddress }: { pet: Pet; walletAddress: string }) {
  const [showBonktip, setShowBonktip] = useState(false);
  const [isGachaEfficiency, setIsGachaEfficiency] = useState(false);
  const { pendingMonsters } = calculateMonsterProgress(
    pet.monstersCaught,
    pet.petWins,
    pet.nextMonsterWins,
  );
  const [showStakeFp, setShowStakeFp] = useState(false);

  const toggleBonktip = () => setShowBonktip(!showBonktip);
  const toggleGachaValue = () => setIsGachaEfficiency(!isGachaEfficiency);
  const toggleStakeFpDisplay = () => setShowStakeFp(!showStakeFp);

  const availableBonks = pet.availableBonks;
  const passHolder = pet.passHolder;
  const shieldBearer = pet.shieldExpires > Math.floor(Date.now() / 1000);
  const staker = pet.stakingPerks;
  const quester = pet.hasAvailableQuests;
  const petStatus = pet.status;
  const topLeftIcon = getTopLeftIcon(
    pet.timeUntilStarving,
    pet.timeUntilAttack,
    pet.timeUntilWheel,
    pet.atkTrainingEndsTimestamp,
    pet.defTrainingEndsTimestamp
  );
  const isZFren = pet.petType === "zfren";
  const isDMonkey = pet.petType === "dmonkey";
  const isGDog = pet.petType === "gdog";
  const isFPanda = pet.petType === "fpanda";
  const isEPepe = pet.petType === "epepe";

  let hibernating = false;
  if (petStatus === 5 ){
    hibernating = true;
  }

  return (
    <div className="mb-2 ml-4 mr-4 mt-2 flex h-[15.75rem] w-[21.5rem] flex-row rounded-lg border-2 border-brown bg-white p-3 [box-shadow:inset_0_-2px_#342e2e]">
      <div className="relative flex w-[54%] flex-col">
        {pet.encodedSvgUrl && (
          <a href={`https://frenpet.xyz/pet/${pet.id}`} target="_blank" rel="noreferrer">
            <div className={`h-16 w-16 overflow-hidden rounded-lg border-2 border-brown bg-green`}>
              {topLeftIcon && (
                <span className="absolute">
                  <img
                    src={topLeftIcon}
                    alt=""
                    className={`h-4 w-4
                      ${topLeftIcon === "/imgs/sword.svg" ? "scale-x-[-1] transform" : ""}
                      ${topLeftIcon === "/imgs/weight.svg" ? "mt-[.09rem] ml-[.12rem] h-[.75rem] w-[.7rem]" : ""}
                      ${topLeftIcon === "/imgs/wheelspin.svg" ? "mt-1 ml-[.095rem] scale-x-[-1] transform" : ""}
                       ${topLeftIcon === "/imgs/apple.svg" ? "h-[.75rem] mt-[.1rem] w-[.85rem]" : ""}
                    `}
                  />
                </span>
              )}
              <span className="absolute ml-[1.85rem] -translate-y-1/3 translate-x-1/3 transform rounded-full border border-brown bg-green px-2 py-[.1rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                lv. {pet.level}
              </span>
              <img
                src={pet.encodedSvgUrl}
                alt={"no pet img"}
                className={`object-cover ${
                  isZFren
                    ? "h-[3.5rem] w-[3.5rem] ml-[.1rem]"
                    : isDMonkey || isGDog || isFPanda
                    ? "h-[2.5rem] w-[2.5rem] ml-[.65rem] mt-2"
                    : ""
                } ${isEPepe ? "mix-blend-multiply" : ""}`}
              />
            </div>
          </a>
        )}
        {hibernating && (
          <span className="absolute ml-[.2rem] mt-[3rem]">
            <img src="/imgs/hibernated.svg" alt="badge" className="h-[.8rem] w-[.8rem]" />
          </span>
        )}
        {!hibernating && passHolder && (
          <span className="absolute ml-[.2rem] mt-[3rem]">
            <img src="/imgs/passBadge.png" alt="" className="h-[.8rem] w-[.8rem]" />
          </span>
        )}
        {!hibernating && !passHolder && staker && (
          <span className="absolute ml-[.3rem] mt-[3rem]">
            <img src="/imgs/frog.svg" alt="" className="h-[.8rem] w-[.8rem]" />
          </span>
        )}
        {!quester && shieldBearer && (
          <span className="absolute ml-[3.03rem] mt-[3rem]">
            <img src="/imgs/shield-solid.svg" alt="" className="h-[.75rem] w-[.75rem]" />
          </span>
        )}
        {quester && (
           <span className="absolute ml-[2.98rem] mt-[3rem]">
           <img src="/imgs/compass.svg" alt="" className="h-[.8rem] w-[.8rem]" />
         </span>
        )}
        <div className="ml-1 mt-2 flex flex-col items-start space-y-4">
          <h2 className="text-md">id #{pet.id}</h2>
          <h2 className="text-md max-w-24 overflow-hidden text-ellipsis whitespace-nowrap">
            {pet.name}
          </h2>
          <h2 className="text-md flex max-w-24 overflow-hidden text-ellipsis whitespace-nowrap">
            <img src="/imgs/piggy-bank.svg" alt="" className="mr-1 mt-[.47rem] h-4 w-4" />
            <div className="mt-1"> {pet.score.toFixed(0)} </div>
          </h2>
          <h2
            className="text-md flex max-w-24 overflow-hidden text-ellipsis whitespace-nowrap"
            onClick={toggleGachaValue}
          >
            {pet.availableGacha > 0 && (
              <span className="absolute -translate-y-[30%] translate-x-[10%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                {pet.availableGacha}
              </span>
            )}
            <img src="/imgs/slot.svg" alt="" className="mr-1 mt-[.47rem] h-4 w-4" />
            <div className="mt-1">
              {" "}
              {isGachaEfficiency ? pet.gachaEfficiency?.toFixed(2) : pet.nextGacha.toFixed(0)}{" "}
            </div>
          </h2>
        </div>
      </div>
      <div className="relative top-[-1%] mr-4 flex h-[104%] w-4 flex-col items-center justify-center">
        <VerticalMeter value={pet.availableBonks} />
        <h2 className="flex items-center text-[.92rem]" onClick={toggleBonktip}>
        <img src="/imgs/bolt.svg" alt="" className="h-5 scale-[160%]" />
        {showBonktip && (
              <div className="absolute z-30 w-[6.9rem] translate-x-[.225rem] -translate-y-[1.2rem] transform rounded border-2 border-brown bg-white px-2 py-1 text-brown [box-shadow:inset_0_-2px_#342e2e]" >
                <div className="flex">
                <img src="/imgs/bolt.svg" alt="" className=" ml-1 mr-1 mt-[0.22rem] h-4 w-4" />•{" "}
                  {availableBonks} / 32
                </div>
              </div>
            )}
          </h2>
      </div>
      <div className="relative grid w-full grid-cols-2 gap-4">
        <div className="flex flex-col gap-[1.75rem]">
        <h2 className="flex items-center text-[.92rem] cursor-pointer" onClick={toggleStakeFpDisplay}>
            <img
              src={showStakeFp ? "/imgs/frog.svg" : "/imgs/ethereum.svg"}
              alt=""
              className="mr-2 h-5 w-5"
            />
            {showStakeFp
              ? `${Number(pet.fpOwed).toFixed(0)} / ${calculateStaked(pet.fpPerSecond)}`
              : Number(pet.rewards) === 0
              ? 0
              : pet.rewards.toFixed(4)}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/hourglass.svg" alt="" className="mr-2 h-5 w-5 scale-x-[-1] transform" />
            <div>{pet.petAgeInDays}</div>
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/carrot.svg" alt="" className="mr-2 h-5 w-5" />
            {calcCarrotBalance(pet.carrotsBalance, pet.carrotsTimestamp).toFixed(2)}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/compass.svg" alt="explorer points" className="mr-2 h-5 w-5" />
            {pet.explorerPoints ?? 0}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img src="/imgs/star.svg" alt="" className="mr-2 h-5 w-5" />
            {pet.stars}
          </h2>
        </div>
        <div className="relative flex flex-col gap-[1.7rem]">
          <h2 className="flex items-center text-[.92rem]">
            {pet.atkLevel > -1 && (
              <span className="absolute z-30 -translate-x-[70%] translate-y-[70%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                lv. {pet.atkLevel}
              </span>
            )}
            <img src="/imgs/sword.svg" alt="" className="mr-1 h-6 w-6 scale-x-[-1] transform" />
            {pet.attackPoints}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            {pet.defLevel > -1 && (
              <span className="absolute -translate-x-[70%] translate-y-[65%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                lv. {pet.defLevel}
              </span>
            )}
            <img src="/imgs/shield.svg" alt="" className="mr-2 h-5 w-5" />
            {pet.defensePoints}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            {pet.winsAvailable > 0 && (
              <span className="absolute -translate-y-[85%] translate-x-[25%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                {pet.winsAvailable}
              </span>
            )}
            <img src="/imgs/win.svg" alt="" className="mr-2 h-5 w-5" />
            {pet.petWins}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            <img
              src="/imgs/shield-solid.svg"
              alt=""
              className="ml-1 mr-2 h-[1.15rem] w-[1.15rem]"
            />
            {pet.shieldCount}
          </h2>
          <h2 className="flex items-center text-[.92rem]">
            {pendingMonsters > 0 && OWNERS.includes(walletAddress) && (
              <span className="absolute -translate-y-[65%] translate-x-[40%] transform rounded-full border border-brown bg-green px-[.35rem] py-[.05rem] text-xs ring-1 ring-inset ring-brown [box-shadow:inset_0_-2px_#342e2e]">
                {pendingMonsters}
              </span>
            )}
            <img src="/imgs/pokeball.svg" alt="" className="mr-1 h-6 w-6" />
            {pet.monstersCaught} / {MAX_MONSTERS}
          </h2>
        </div>
      </div>
    </div>
  );
}
