import { PetScores, Score } from "../../../common/types";
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  LineChart,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import { formatDayTime } from "../../helpers/helpers";
import { useMemo, useState } from "react";
import { colors, customTooltip, customLegend, secondsDict } from "../utils/ChartUtils";

const timestamps = {
  oneDay: 86400,
  oneWeek: 604800,
  oneMonth: 2592000,
};

function Button({
  text,
  selected,
  setSelected,
}: {
  text: string;
  selected: boolean;
  setSelected: (text: string) => void;
}) {
  return (
    <button
      className={`rounded-lg border-[1px] border-brown px-2 py-1 ${selected ? "bg-brown text-white" : "bg-white text-brown"}`}
      onClick={() => setSelected(text)}
    >
      {text}
    </button>
  );
}

function getGrowth({
  scores,
  delta,
  index,
  average = true,
}: {
  scores: Score[];
  delta?: number;
  index?: number;
  average?: boolean;
}) {
  const latestScore = scores[scores.length - 1];
  let closestFirstScore;
  if (delta) {
    const firstTimestamp = latestScore.timestamp - delta;
    closestFirstScore = scores.find((score) => score.timestamp >= firstTimestamp)!;
  } else if (index) {
    closestFirstScore = scores[index];
  } else {
    closestFirstScore = scores[0];
  }
  const deltaScore = latestScore.score - closestFirstScore.score;
  const deltaTimestamp = latestScore.timestamp - closestFirstScore.timestamp;
  if (average && delta) {
    return (deltaScore / deltaTimestamp) * delta;
  }
  return deltaScore;
}
export function ScoreChart({
  scores,
  petScores,
  showPets,
}: {
  scores: Score[];
  petScores: PetScores;
  showPets: boolean;
}) {
  const [selected, setSelected] = useState("1D");
  const [showTooltip, setShowTooltip] = useState(false);
  const [reference, setReference] = useState<number | undefined>(undefined);
  const firstPet = Object.keys(petScores)[0];
  const [activePets, setActivePets] = useState<{ [key: string]: boolean }>(
    Object.keys(petScores).reduce(
      (acc, key) => ({ ...acc, [key]: key === firstPet }),
      {}
    )
  );
  const isTouchDevice = "ontouchstart" in window;
  const toggleTooltip = () => setShowTooltip(!showTooltip);

  const getFilteredScoresData = () => {
    if (showPets) {
      return Object.entries(petScores).reduce((acc, [name, scores]) => {
        acc[name] = scores.filter(
          (score) => score.timestamp > Date.now() / 1000 - secondsDict[selected]
        );
        return acc;
      }, {} as PetScores);
    } else {
      return {
        Total: scores.filter(
          (score) => score.timestamp > Date.now() / 1000 - secondsDict[selected]
        ),
      };
    }
  };

  const filteredScoresData = getFilteredScoresData();

  const { largestGainer, largestLoser, maxGain, maxLoss } = useMemo(() => {
    let maxGain = -Infinity;
    let maxLoss = Infinity;
    let largestGainer = "";
    let largestLoser = "";

    Object.entries(petScores).forEach(([name, scores]) => {
      const gain = getGrowth({ scores, delta: secondsDict[selected], average: false });

      if (gain > maxGain) {
        maxGain = gain;
        largestGainer = name;
      }

      if (gain < maxLoss) {
        maxLoss = gain;
        largestLoser = name;
      }
    });

    return { largestGainer, largestLoser, maxGain, maxLoss };
  }, [petScores, selected]);

  const { maxScore, minScore, maxTimestamp, minTimestamp } = useMemo(() => {
    let maxScore = -Infinity;
    let minScore = Infinity;
    let maxTimestamp = -Infinity;
    let minTimestamp = Infinity;
    Object.entries(filteredScoresData).forEach(([name, scores]) => {
      if (showPets && !activePets[name]) return;
      scores.forEach(({ score, timestamp }) => {
        if (score > maxScore) maxScore = score;
        if (score < minScore) minScore = score;
        if (timestamp > maxTimestamp) maxTimestamp = timestamp;
        if (timestamp < minTimestamp) minTimestamp = timestamp;
      });
    });
    return { maxScore, minScore, maxTimestamp, minTimestamp };
  }, [filteredScoresData, showPets, activePets]);

  return (
    <div className="my-2 ml-4 mr-4 flex w-[80%] flex-col items-start justify-center rounded-lg border-2 border-brown bg-white p-4 text-sm [box-shadow:inset_0_-2px_#342e2e]">
      <div className="mb-4 flex w-full content-between justify-between font-sans text-xl">
      <div
          onClick={toggleTooltip}
          className="flex cursor-default"
          onMouseEnter={() => !isTouchDevice && setShowTooltip(true)}
          onMouseLeave={() => !isTouchDevice && setShowTooltip(false)}
        >
          {showTooltip && (
            <div className="absolute z-10 ml-16 w-max whitespace-nowrap rounded border-2 border-brown bg-white px-2 py-1 text-sm text-brown [box-shadow:inset_0_-2px_#342e2e]">
              <div className="flex">
                <span className="mr-1">24h •</span>
                <span>
                  {getGrowth({
                    scores: scores,
                    delta: timestamps["oneDay"],
                    average: true,
                  }).toFixed(0)}
                </span>
              </div>
              <div className="mt-2 flex">
                <span className="mr-1">7D •</span>
                <span>
                  {getGrowth({
                    scores: scores,
                    delta: timestamps["oneWeek"],
                    average: true,
                  }).toFixed(0)}
                </span>
              </div>
              <div className="mt-2 flex">
                <span className="mr-1">30D •</span>
                <span>
                  {getGrowth({
                    scores: scores,
                    delta: timestamps["oneMonth"],
                    average: true,
                  }).toFixed(0)}
                </span>
              </div>
              <div className="mt-2 flex">
                <span className="mr-1">All Time •</span>
                <span>{scores[scores.length - 1].score.toFixed(0)}</span>
              </div>
              <div className="mt-2 flex">
                <span className="mr-[.01rem]">Gainer • </span>
                <span>&nbsp;</span>
                <span>{largestGainer} • {maxGain.toFixed(0)}</span>
              </div>
              <div className="mt-2 flex">
                <span className="mr-1">Loser •</span>
                <span>&nbsp;</span>
                <span>{largestLoser} • {maxLoss.toFixed(0)}</span>
              </div>
            </div>
          )}
          Score
          </div>
        <div className="space-x-1 font-sans text-sm">
          <Button text="1D" selected={selected === "1D"} setSelected={setSelected} />
          <Button text="7D" selected={selected === "7D"} setSelected={setSelected} />
          <Button text="1M" selected={selected === "1M"} setSelected={setSelected} />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          data={filteredScoresData.Total}
          onClick={(e) => {
            if (isTouchDevice) return;
            if (reference) {
              setReference(undefined);
            } else {
              setReference(Number(e?.activeLabel));
            }
          }}
          key="scoreChart"
          margin={{ left: 15 }}
        >
          <XAxis
            stroke="#342e2e"
            dataKey="timestamp"
            type="number"
            domain={selected === "1D"
              ? [
                () => minTimestamp - (maxTimestamp - minTimestamp) * .001,
                () => maxTimestamp
              ]
            : ['dataMin', 'dataMax'] }
            tickFormatter={formatDayTime}
            allowDuplicatedCategory={false}
            tickCount={100}
          />
          <YAxis
            dataKey="score"
            stroke="#342e2e"
            domain={[() => minScore, () => maxScore]}
            tickCount={100}
            tickFormatter={(tick) => tick.toFixed(0)}
          />
          <ReferenceLine
            x={reference}
            stroke="#342e2e"
            strokeDasharray="6 3"
            strokeWidth={2}
            key="scoreReferenceLine"
          />
          {Object.entries(filteredScoresData).map(([name, scores], index) => (
            <Line
              hide={showPets ? !activePets[name] : false}
              dataKey="score"
              data={scores.map((score) => ({
                ...score,
                timestamp: Math.floor(score.timestamp / 100) * 100,
              }))}
              name={name}
              key={name}
              stroke={colors[index % colors.length]}
              type="monotone"
              dot={false}
            />
          ))}
          <Tooltip
            labelFormatter={formatDayTime}
            content={customTooltip(0, reference, filteredScoresData)}
          />
          {showPets && <Legend content={customLegend(activePets, setActivePets)} />}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
