import { useEffect, useState } from "react";
import { Pet } from "../../../common/types";
import { utils } from "web3";
import { blacklistedAddresses } from "../../helpers/helpers";

function fetchWalletHistory() {
  const _walletAddresses = localStorage.getItem("walletAddresses");
  let walletAddresses: string[] = [];
  if (_walletAddresses) {
    walletAddresses = JSON.parse(_walletAddresses) as string[];
  }
  return walletAddresses;
}

export function WalletSelector({
  pets,
  walletAddress,
  setWalletAddress,
  setIsLoading,
  isLoading,
  isPhone
}: {
  pets: Pet[];
  walletAddress: string;
  setWalletAddress: (address: string) => void;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
  isPhone: boolean;
}) {
  const [walletAddressHistory, setWalletAddressHistory] = useState<string[]>(fetchWalletHistory());
  const [displayWalletHistory, setDisplayWalletHistory] = useState(false);
  const [onRemoveButton, setOnRemoveButton] = useState(false);
  const [sortKey, setSortKey] = useState<keyof Pet>("score");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [inputValue, setInputValue] = useState(walletAddress);

  function addWalletAddressToHistory(newWalletAddress: string) {
    let walletAddresses = [...walletAddressHistory];
    walletAddresses = walletAddresses.filter((address) => address !== newWalletAddress);
    walletAddresses.unshift(newWalletAddress);
    setWalletAddressHistory(walletAddresses);
    localStorage.setItem("walletAddresses", JSON.stringify(walletAddresses));
  }

  function removeWalletAddressFromHistory(walletAddress: string) {
    let walletAddresses = [...walletAddressHistory];
    walletAddresses = walletAddresses.filter((address) => address !== walletAddress);
    setWalletAddressHistory(walletAddresses);
    localStorage.setItem("walletAddresses", JSON.stringify(walletAddresses));
  }

  useEffect(() => {
    setSortKey("score");
    setSortOrder("desc");
  }, [walletAddress]);

  function fetchPets(newWalletAddress: string) {
    newWalletAddress = newWalletAddress.trim().replace("x", "x");

    if (newWalletAddress.length === 40 && !newWalletAddress.startsWith("0x")) {
      newWalletAddress = "0x" + newWalletAddress;
    }
    if (newWalletAddress && newWalletAddress.length === 42 && newWalletAddress.startsWith("0x")) {
      try {
        const checksummedAddress = utils.toChecksumAddress(newWalletAddress);
        if (blacklistedAddresses.has(checksummedAddress)) {
          return;
        }
        setWalletAddress(checksummedAddress);
        setInputValue(checksummedAddress);
        addWalletAddressToHistory(checksummedAddress);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    const walletAddresses = fetchWalletHistory();
    if (walletAddresses.length > 0) {
      fetchPets(walletAddresses[0]);
    }
    setWalletAddressHistory(walletAddresses);
  }, []);

  const handleSearch = (address?: string) => {
    setDisplayWalletHistory(false);
    setOnRemoveButton(false);
    address ? fetchPets(address) : fetchPets(inputValue);
  };

  return (
    <div
      className="relative mr-2 mt-5 flex h-8 w-60 flex-col items-center bg-green"
      onMouseLeave={() => {
        setDisplayWalletHistory(false);
        setOnRemoveButton(false);
      }}
    >
      <div className="z-50 flex h-full w-full flex-row items-center rounded-md border-2 border-brown bg-white">
        {isPhone && pets.length !== 0 && isLoading ? (
          <img src="/imgs/refresh.svg" alt="Refreshing" className="spin z-20 ml-2 h-4 w-4" />
        ) : (
          <img src="/imgs/search.svg" alt="" className="z-20 ml-2 h-4 w-4" onClick={() => handleSearch()} />
        )}
        <input
          type="text"
          placeholder="wallet address..."
          value={inputValue}
          className="w-full rounded-md pl-2 focus:outline-none"
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={() => walletAddressHistory.length > 1 && setDisplayWalletHistory(true)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch(inputValue);
            }
          }}
          onMouseEnter={() => walletAddressHistory.length > 1 && setDisplayWalletHistory(true)}
        />
      </div>
      {displayWalletHistory && walletAddressHistory.length > 0 && (
        <div className="full absolute top-0 z-40 flex w-full cursor-default flex-col rounded-md border-2 border-t-0 border-brown bg-white">
          {walletAddressHistory
            .filter((address) => address !== walletAddress)
            .map((address) => (
              <div
                key={address}
                className={`flex h-full items-end justify-between border-b-2 border-brown p-1 first:h-[66px] first:rounded-t-md first:border-t-0 last:border-b-0 ${!onRemoveButton ? "hover:bg-brown hover:text-white" : ""}`}
                onClick={() => handleSearch(address)}
              >
                <span className="truncate">{address}</span>
                <div
                  className="rounded-md border-2 border-brown bg-white px-2 text-brown hover:bg-green hover:text-white"
                  onMouseEnter={() => setOnRemoveButton(true)}
                  onMouseLeave={() => setOnRemoveButton(false)}
                  onClick={(e) => {
                    e.stopPropagation();
                    removeWalletAddressFromHistory(address);
                  }}
                >
                  <img src="/imgs/x.svg" alt="" className="h-6 w-6" />
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
