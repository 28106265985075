import { Reward, Score, PetRewards, PetScores, PGoldBalance, PGoldPrice, FpPrice } from "../../../common/types";
import { PGoldBalanceChart } from "../charts/PGoldBalanceChart";
import { RewardChart } from "../charts/RewardChart";
import { ScoreChart } from "../charts/ScoreChart";
import { useMemo, useState } from "react";

function ToggleButton({ value }: { value: boolean }) {
  return (
    <div
      className={`flex h-4 w-7 flex-row items-center rounded-full border-2 border-brown bg-white ${
        value ? "justify-end" : "justify-start"
      }`}
    >
      <div className={`h-2 w-2 m-1 rounded-full bg-brown`}></div>
    </div>
  );
}

export function Charts({
  rewards,
  scores,
  petRewards,
  petScores,
  pGoldBalance,
  pGoldPrices,
  fpPrices
}: {
  rewards: Reward[];
  scores: Score[];
  petRewards: PetRewards;
  petScores: PetScores;
  pGoldBalance: PGoldBalance[];
  pGoldPrices: PGoldPrice[];
  fpPrices: FpPrice[];
}) {
  const [showPets, setHideTotal] = useState(false);

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div
        className="flex items-center justify-center cursor-default"
        onClick={() => setHideTotal(!showPets)}
      >
        <p className="text-sm font-sans mr-2">Show Pets</p> <ToggleButton value={showPets} />
      </div>
      <RewardChart rewards={rewards} petRewards={petRewards} showPets={showPets} />
      <ScoreChart scores={scores} petScores={petScores} showPets={showPets} />
      <PGoldBalanceChart pGoldBalances={pGoldBalance}  pGoldPrices={pGoldPrices}
        fpPrices={fpPrices}/>
    </div>
  );
}
