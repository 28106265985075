import { FloorPriceResponse, GetCatsResponse, GetPetsResponse, PGoldPrice, PotionStatsResponse, TreasuryBalance } from "../../common/types";
import { Pet } from "../../common/types";
import { GasPrice, FpPrice, EthPrice } from "../../common/types";

const serverAddress = process.env.REACT_APP_LOCAL_IP || "localhost";
console.log("Server address: ", serverAddress);

let serverURL: string, wssURL: string;
if (process.env.NODE_ENV === "development") {
  serverURL = `http://${serverAddress}:3080`;
  wssURL = `ws://${serverAddress}:3080`;
} else {
  serverURL = "https://fpanalytica.tech:3080";
  wssURL = "wss://fpanalytica.tech:3080";
}

let topPetsURL: string;
if (process.env.NODE_ENV === "development") {
  topPetsURL = `http://${serverAddress}:3080/top-pets`;
} else {
  topPetsURL = "https://fpanalytica.tech:3080/top-pets";
}

async function getTopPets() {
  const response = await fetch(topPetsURL);
  const data = await response.json();
  return data as Pet[];
}

let gasPriceURL: string;
if (process.env.NODE_ENV === "development") {
  gasPriceURL = `http://${serverAddress}:3080/gas-prices`;
} else {
  gasPriceURL = "https://fpanalytica.tech:3080/gas-prices";
}

async function getGasPrices() {
  const response = await fetch(gasPriceURL);
  const data = await response.json();
  return data as GasPrice[];
}

let fpPriceURL: string;
if (process.env.NODE_ENV === "development") {
  fpPriceURL = `http://${serverAddress}:3080/fp-prices`;
} else {
  fpPriceURL = "https://fpanalytica.tech:3080/fp-prices";
}

async function getFpPrices() {
  const response = await fetch(fpPriceURL);
  const data = await response.json();
  return data as FpPrice[];
}

let ethPriceURL: string;
if (process.env.NODE_ENV === "development") {
  ethPriceURL = `http://${serverAddress}:3080/eth-prices`;
} else {
  ethPriceURL = "https://fpanalytica.tech:3080/eth-prices";
}

async function getEthPrices() {
  const response = await fetch(ethPriceURL);
  const data = await response.json();
  return data as EthPrice[];
}

let topCatsURL: string;
if (process.env.NODE_ENV === "development") {
  topCatsURL = `http://${serverAddress}:3080/top-cats`;
} else {
  topCatsURL = "https://fpanalytica.tech:3080/top-cats";
}

let topDragonsURL: string;
if (process.env.NODE_ENV === "development") {
  topDragonsURL = `http://${serverAddress}:3080/top-dragons`;
} else {
  topDragonsURL = "https://fpanalytica.tech:3080/top-dragons";
}

let topPepesURL: string;
if (process.env.NODE_ENV === "development") {
  topPepesURL = `http://${serverAddress}:3080/top-pepes`;
} else {
  topPepesURL = "https://fpanalytica.tech:3080/top-pepes";
}

let topSheepURL: string;
if (process.env.NODE_ENV === "development") {
  topSheepURL = `http://${serverAddress}:3080/top-sheep`;
} else {
  topSheepURL = "https://fpanalytica.tech:3080/top-sheep";
}

let topPandasURL: string;
if (process.env.NODE_ENV === "development") {
  topPandasURL = `http://${serverAddress}:3080/top-pandas`;
} else {
  topPandasURL = "https://fpanalytica.tech:3080/top-pandas";
}

let topPenguinsURL: string;
if (process.env.NODE_ENV === "development") {
  topPenguinsURL = `http://${serverAddress}:3080/top-penguins`;
} else {
  topPenguinsURL = "https://fpanalytica.tech:3080/top-penguins";
}

let topDogsURL: string;
if (process.env.NODE_ENV === "development") {
  topDogsURL = `http://${serverAddress}:3080/top-dogs`;
} else {
  topDogsURL = "https://fpanalytica.tech:3080/top-dogs";
}

let topMonkeysURL: string;
if (process.env.NODE_ENV === "development") {
  topMonkeysURL = `http://${serverAddress}:3080/top-monkeys`;
} else {
  topMonkeysURL = "https://fpanalytica.tech:3080/top-monkeys";
}

let pGoldPriceURL: string;
if (process.env.NODE_ENV === "development") {
  pGoldPriceURL = `http://${serverAddress}:3080/pgold-prices`;
} else {
  pGoldPriceURL = "https://fpanalytica.tech:3080/pgold-prices";
}

async function getPgoldPrices(): Promise<PGoldPrice[]> {
  const response = await fetch(pGoldPriceURL);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data as PGoldPrice[];
}

async function getAllCats() {
  const response = await fetch(topCatsURL);
  const data = await response.json();
  return data as Pet[];
}

async function getAllDragons() {
  const response = await fetch(topDragonsURL);
  const data = await response.json();
  return data as Pet[];
}

async function getAllPepes() {
  const response = await fetch(topPepesURL);
  const data = await response.json();
  return data as Pet[];
}

async function getAllPandas() {
  const response = await fetch(topPandasURL);
  const data = await response.json();
  return data as Pet[];
}

async function getAllPenguins() {
  const response = await fetch(topPenguinsURL);
  const data = await response.json();
  return data as Pet[];
}

async function getAllSheep() {
  const response = await fetch(topSheepURL);
  const data = await response.json();
  return data as Pet[];
}

async function getAllDogs() {
  const response = await fetch(topDogsURL);
  const data = await response.json();
  return data as Pet[];
}

async function getAllMonkeys() {
  const response = await fetch(topMonkeysURL);
  const data = await response.json();
  return data as Pet[];
}

let topOldPetsURL: string;
if (process.env.NODE_ENV === "development") {
  topOldPetsURL = `http://${serverAddress}:3080/top-old`;
} else {
  topOldPetsURL = "https://fpanalytica.tech:3080/top-old";
}

async function getTopOldPets() {
  const response = await fetch(topOldPetsURL);
  const data = await response.json();
  return data as Pet[];
}

let challengePetsURL: string;
if (process.env.NODE_ENV === "development") {
  challengePetsURL = `http://${serverAddress}:3080/100-day-challenge`;
} else {
  challengePetsURL = "https://fpanalytica.tech:3080/100-day-challenge";
}

async function getChallengePets() {
  const response = await fetch(challengePetsURL);
  const data = await response.json();
  return data as Pet[];
}

async function addChallengePet(petId: number) {
  const response = await fetch(challengePetsURL + `/${petId}`);
  const data = await response.json();
  return data;
}

async function removeChallengePet(petId: number) {
  const response = await fetch(challengePetsURL + `/remove/${petId}`);
  const data = await response.json();
  return data;
}

let ethBalanceURL: string;
if (process.env.NODE_ENV === "development") {
  ethBalanceURL = `http://${serverAddress}:3080`;
} else {
  ethBalanceURL = "https://fpanalytica.tech:3080";
}

async function getEthBalance(walletAddress: string): Promise<string> {
  const url = `${ethBalanceURL}/eth-balance/${walletAddress}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.balance;
  } catch (error) {
    console.error('Failed to fetch Ethereum balance:', error);
    throw error;
  }
}

let fpBalanceURL: string;
if (process.env.NODE_ENV === "development") {
  fpBalanceURL = `http://${serverAddress}:3080`;
} else {
  fpBalanceURL = "https://fpanalytica.tech:3080";
}

async function getFpBalance(walletAddress: string): Promise<string> {
  const url = `${fpBalanceURL}/fp-balance/${walletAddress}`;

  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.balance;
  } catch (error) {
    console.error('Failed to fetch fp balance:', error);
    throw error;
  }
}

let topListingsURL: string;
if (process.env.NODE_ENV === "development") {
  topListingsURL = `http://${serverAddress}:3080/top-listings`;
} else {
  topListingsURL = "https://fpanalytica.tech:3080/top-listings";
}

async function getTopListings() {
  const response = await fetch(topListingsURL);
  const data = await response.json();
  return data as Pet[];
}

let potionStatsURL: string;
if (process.env.NODE_ENV === "development") {
  potionStatsURL = `http://${serverAddress}:3080/potion-stats`;
} else {
  potionStatsURL = "https://fpanalytica.tech:3080/potion-stats";
}

async function getPotionStats() {
  const response = await fetch(potionStatsURL);
  const data = await response.json();
  return data as FloorPriceResponse;
}

let speciesCountsURL: string;
if (process.env.NODE_ENV === "development") {
  speciesCountsURL = `http://${serverAddress}:3080/species-counts`;
} else {
  speciesCountsURL = "https://fpanalytica.tech:3080/species-counts";
}

async function getSpeciesCounts(): Promise<{ [species: string]: number }> {
  try {
    const response = await fetch(speciesCountsURL);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json(); 
    
    const transformedData = data.reduce((acc: { [species: string]: number }, entry: { species: string; count: number }) => {
      acc[entry.species] = entry.count;
      return acc;
    }, {});

    return transformedData;
  } catch (error) {
    console.error("Failed to fetch species counts:", error);
    throw error;
  }
}

let cacheExpiryTimeURL: string;
if (process.env.NODE_ENV === "development") {
  cacheExpiryTimeURL = `http://${serverAddress}:3080/cache-expiry-time`;
} else {
  cacheExpiryTimeURL = "https://fpanalytica.tech:3080/cache-expiry-time";
}
async function getCacheExpiryTime(): Promise<number> {
  try {
    const response = await fetch(cacheExpiryTimeURL);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.cacheExpiryTime;
  } catch (error) {
    console.error("Failed to fetch cache expiry time:", error);
    return 0;
  }
}

let topStarsLeaderboardURL: string;
if (process.env.NODE_ENV === "development") {
  topStarsLeaderboardURL = `http://${serverAddress}:3080/leaderboard/top-stars`;
} else {
  topStarsLeaderboardURL = "https://fpanalytica.tech:3080/leaderboard/top-stars";
}

async function getTopStarsLeaderboard(): Promise<Pet[]> {
  try {
    const response = await fetch(topStarsLeaderboardURL);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data as Pet[];
  } catch (error) {
    console.error("Failed to fetch top stars leaderboard:", error);
    throw error;
  }
}

let treasuryBalancesURL: string;
if (process.env.NODE_ENV === "development") {
  treasuryBalancesURL = `http://${serverAddress}:3080/treasury-balances`;
} else {
  treasuryBalancesURL = "https://fpanalytica.tech:3080/treasury-balances";
}

async function getTreasuryBalances(): Promise<TreasuryBalance[]> {
  const response = await fetch(treasuryBalancesURL);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const data = await response.json();
  return data as TreasuryBalance[];
}

export {
  getTopPets,
  getGasPrices,
  getAllCats,
  getFpPrices,
  getEthPrices,
  getTopOldPets,
  getChallengePets,
  addChallengePet,
  removeChallengePet,
  getEthBalance,
  getFpBalance,
  getTopListings,
  getCacheExpiryTime,
  getPotionStats,
  getPgoldPrices,
  getSpeciesCounts,
  getTopStarsLeaderboard,
  getAllDragons,
  getAllDogs,
  getAllMonkeys,
  getAllPandas,
  getAllPenguins,
  getAllPepes,
  getAllSheep,
  getTreasuryBalances,
  serverURL,
  wssURL,
};
