import { useEffect, useState } from "react";
import { Pet } from "../../../common/types";
import { PetStatCard } from "../cards/PetStatCard";

export function PetLeaderboard({
  topPets,
  speciesCounts,
  selectedViewer,
}: {
  topPets: Pet[];
  speciesCounts?: { species: string; count: number }[] | null;
  selectedViewer: string;
}) {
  const [sortedPets, setSortedPets] = useState<Pet[]>([]);
  const [currentSpeciesCount, setCurrentSpeciesCount] = useState<{ species: string; count: number } | null>(null);

  useEffect(() => {
    let sorted = [...topPets];
    if (selectedViewer === "topoldpets") {
      sorted = sorted.sort((a, b) => b.petAgeInDays - a.petAgeInDays);
    } else if (selectedViewer === "topstars") {
      sorted = sorted.sort((a, b) => b.stars - a.stars);
    } else {
      sorted = sorted.sort((a, b) => b.score - a.score);
    }
    setSortedPets(sorted);
  }, [topPets, selectedViewer]);

  useEffect(() => {
    if (speciesCounts && sortedPets.length > 0) {
      const displayedSpecies = sortedPets[0].petType;
      const matchingSpecies = speciesCounts.find(
        (entry) => entry.species.toLowerCase() === displayedSpecies.toLowerCase()
      );
      setCurrentSpeciesCount(matchingSpecies || null);
    }
  }, [speciesCounts, sortedPets]);

  const hideSpeciesCount = ["toppets", "topoldpets", "topstars"].includes(selectedViewer);

  const formatSpeciesName = (species: string): string => {
    const trimmedName = species.slice(1);
    const formattedName =
      trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1);

    if (trimmedName.toLowerCase() === "sheep") {
      return formattedName;
    }

    return formattedName + "s";
  };

  return (
    <div className="flex flex-col">
      {!hideSpeciesCount && currentSpeciesCount && (
        <div className="flex w-full flex-col items-center">
          <h3 className="mb-1 mt-1 text-md font-semibold">
            {formatSpeciesName(currentSpeciesCount.species)} Alive • {currentSpeciesCount.count}
          </h3>
        </div>
      )}
      <ul className="mb-2 flex w-[90%] flex-col">
        {sortedPets.map((pet, index) => (
          <li key={pet.id} className="flex flex-col">
            <div className="flex flex-col">
              <PetStatCard pet={pet} />
            </div>
            <div className="flex flex-row justify-center">
              <div className="mb-3 ml-10 flex">
                <img src="/imgs/rank.svg" alt="rank" className="ml-2 mt-1 h-5 w-5" />
                <div className="ml-1 mt-1">{index + 1}</div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
