import { DefaultLegendContentProps, TooltipProps } from "recharts";
import { PetRewards, PetScores, Score, Reward } from "../../../common/types";
import { formatDayTime } from "../../helpers/helpers";

export const colors = [
  "#342e2e",
  "#95cfec",
  "#217ab5",
  "#f893ca",
  "#d0368b",
  "#f5735f",
  "#ec4a31",
  "#ff8f9d",
  "#e94458",
  "#fca360",
  "#d46b2f",
  "#f9cc67",
  "#a8660f",
  "#52c1a3",
  "#0b7063",
  "#393939",
  "#342e2e",
];

export function customLegend(
  activePets: { [key: string]: boolean },
  setActivePets: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>,
) {
  return (props: DefaultLegendContentProps) => {
    const { payload } = props;
    return (
      <div className="ml-12 grid grid-cols-2 text-xs sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
        {payload?.map((entry, index) => (
          <div
            key={index}
            className="mr-2 flex select-none items-center"
            onClick={() => {
              entry.value &&
                setActivePets({ ...activePets, [entry.value]: !activePets[entry.value] });
            }}
          >
            <svg width="20" height="10" viewBox="0 0 512 512" fill={entry.color}>
              <path d="M226.5 92.9c14.3 42.9-.3 86.2-32.6 96.8s-70.1-15.6-84.4-58.5s.3-86.2 32.6-96.8s70.1 15.6 84.4 58.5zM100.4 198.6c18.9 32.4 14.3 70.1-10.2 84.1s-59.7-.9-78.5-33.3S-2.7 179.3 21.8 165.3s59.7 .9 78.5 33.3zM69.2 401.2C121.6 259.9 214.7 224 256 224s134.4 35.9 186.8 177.2c3.6 9.7 5.2 20.1 5.2 30.5v1.6c0 25.8-20.9 46.7-46.7 46.7c-11.5 0-22.9-1.4-34-4.2l-88-22c-15.3-3.8-31.3-3.8-46.6 0l-88 22c-11.1 2.8-22.5 4.2-34 4.2C84.9 480 64 459.1 64 433.3v-1.6c0-10.4 1.6-20.8 5.2-30.5zM421.8 282.7c-24.5-14-29.1-51.7-10.2-84.1s54-47.3 78.5-33.3s29.1 51.7 10.2 84.1s-54 47.3-78.5 33.3zM310.1 189.7c-32.3-10.6-46.9-53.9-32.6-96.8s52.1-69.1 84.4-58.5s46.9 53.9 32.6 96.8s-52.1 69.1-84.4 58.5z" />
            </svg>
            <span className={`${!activePets[entry.value] ? "line-through" : ""}`}>
              {entry.value}
            </span>
          </div>
        ))}
      </div>
    );
  };
}

export function customTooltip(
  valueDecimals: number,
  reference?: number,
  values?: PetScores | PetRewards,
) {
  return (props: TooltipProps<number, string>) => {
    const { active, payload, label } = props;

    if (active && payload && payload.length) {
      const date = new Date(label * 1000);

      let referenceValues: { [key: string]: { delta: number; deltaPct:number; average: number } } | undefined;
      if (reference && values) {
        referenceValues = Object.entries(values).reduce((acc, [name, petValues]) => {
          let delta, deltaPct, average, firstValue, secondValue, key;
          if (Array.isArray(petValues)) {
            if (label > reference) {
              firstValue = petValues.find((value) => value.timestamp >= reference);
              secondValue = petValues.find((value) => value.timestamp >= label);
            } else {
              firstValue = petValues.find((value) => value.timestamp >= label);
              secondValue = petValues.find((value) => value.timestamp >= reference);
            }
          }
          key = petValues.every((value: Score | Reward) => "score" in value) ? "score" : "reward";
          if (firstValue && secondValue) {
            delta = secondValue[key] - firstValue[key];
            average = (delta / (secondValue.timestamp - firstValue.timestamp)) * 86400;
            deltaPct = delta / (firstValue[key] > 0 ? firstValue[key] : 1) * 100;
            if (deltaPct > 1000) deltaPct = 1000.1;
            if (isNaN(average)) average = 0;
          }
          return { ...acc, [name]: { delta, deltaPct, average } };
        }, {});
      }
      return (
        <div className="rounded-md border-2 border-brown bg-white bg-opacity-60 p-2 text-sm [box-shadow:inset_0_-2px_#342e2e]">
          <div className="text-md flex w-full justify-center border-b-2 border-brown font-sans">
            {date.toLocaleString([], {
              month: "numeric",
              day: "numeric",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
          </div>
          <div
            className={`grid ${referenceValues ? "grid-cols-[1fr_55px_75px_55px]" : "grid-cols-[1fr_55px]"} gap-x-4 pt-1`}
          >
            {!referenceValues ? (
              <>
                <div className="flex w-full justify-center">Name</div>
                <div className="flex w-full justify-center">Value</div>
              </>
            ) : (
              <>
                <div className="flex w-full justify-center">Name</div>
                <div className="flex w-full justify-center">Change</div>
                <div className="flex w-full justify-center">% Change</div>
                <div className="flex w-full justify-center">Avg 1D</div>
              </>
            )}

            {payload.map((entry) => (
              <>
                <div className="flex flex-row items-center">
                  <svg width="20" height="10" viewBox="0 0 512 512" fill={entry.color}>
                    <path d="M226.5 92.9c14.3 42.9-.3 86.2-32.6 96.8s-70.1-15.6-84.4-58.5s.3-86.2 32.6-96.8s70.1 15.6 84.4 58.5zM100.4 198.6c18.9 32.4 14.3 70.1-10.2 84.1s-59.7-.9-78.5-33.3S-2.7 179.3 21.8 165.3s59.7 .9 78.5 33.3zM69.2 401.2C121.6 259.9 214.7 224 256 224s134.4 35.9 186.8 177.2c3.6 9.7 5.2 20.1 5.2 30.5v1.6c0 25.8-20.9 46.7-46.7 46.7c-11.5 0-22.9-1.4-34-4.2l-88-22c-15.3-3.8-31.3-3.8-46.6 0l-88 22c-11.1 2.8-22.5 4.2-34 4.2C84.9 480 64 459.1 64 433.3v-1.6c0-10.4 1.6-20.8 5.2-30.5zM421.8 282.7c-24.5-14-29.1-51.7-10.2-84.1s54-47.3 78.5-33.3s29.1 51.7 10.2 84.1s-54 47.3-78.5 33.3zM310.1 189.7c-32.3-10.6-46.9-53.9-32.6-96.8s52.1-69.1 84.4-58.5s46.9 53.9 32.6 96.8s-52.1 69.1-84.4 58.5z" />
                  </svg>
                  <div>{entry.name}</div>
                </div>
                {referenceValues ? (
                  <>
                    <div>
                      {referenceValues[entry.name!].delta >= 0 ? "+" : ""}
                      {referenceValues[entry.name!].delta.toFixed(valueDecimals)}
                    </div>
                    <div>
                      {referenceValues[entry.name!].deltaPct >= 0 ? "+" : ""}
                      {referenceValues[entry.name!].deltaPct > 1000 ? ">" : ""}
                      {referenceValues[entry.name!]?.deltaPct.toFixed(0)}%
                    </div>
                    <div>{referenceValues[entry.name!]?.average.toFixed(valueDecimals)}</div>
                  </>
                ) : (
                  <div>{entry.value?.toFixed(valueDecimals)}</div>
                )}
              </>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };
}

export const secondsDict: { [key: string]: number } = {
  "1D": 86400,
  "7D": 604800,
  "1M": 2592000,
  All: Infinity,
};

export type ReferenceValues = {
  [key: string]: {
    delta: number;
    average: number;
  };
};

export function PGoldBalTooltip({
  active,
  payload,
  label,
  selectedCurrency,
}: {
  active?: boolean;
  payload?: any[];
  label?: number;
  selectedCurrency: string;
}) {
  if (active && payload && payload.length && label !== undefined) {
    const value = payload[0].value.toFixed(2);
    const unit =
      selectedCurrency === "USD"
        ? "$USD"
        : selectedCurrency === "FP"
        ? "$FP"
        : "$pG";

    return (
      <div className="rounded-lg bg-white p-2 text-brown shadow-lg">
        <div className="text-md flex w-full justify-center border-b-2 border-brown font-sans">
          {formatDayTime(label)}
        </div>
        <div className="mt-2 text-center font-sans">
          {`${value} ${unit}`}
        </div>
      </div>
    );
  }
  return null;
}

export function PGoldTooltip({
  active,
  payload,
  label,
  selectedCurrency,
}: {
  active?: boolean;
  payload?: any[];
  label?: number;
  selectedCurrency: string;
}) {
  if (active && payload && payload.length && label !== undefined) {
    const value = payload[0].value.toFixed(4);
    const unit =
      selectedCurrency === "USD"
        ? "$USD"
        : selectedCurrency === "FP"
        ? "$FP"
        : "$pG";

    return (
      <div className="rounded-lg bg-white p-2 text-brown shadow-lg">
        <div className="text-md flex w-full justify-center border-b-2 border-brown font-sans">
          {formatDayTime(label)}
        </div>
        <div className="mt-2 text-center font-sans">
          {`${value} ${unit}`}
        </div>
      </div>
    );
  }
  return null;
}

export function ChartTooltip({
  active,
  payload,
  label,
  unit,
  valueDecimals = 4,
}: {
  active?: boolean;
  payload?: any[];
  label?: number;
  unit: string;
  valueDecimals?: number;
}) {
  if (active && payload && payload.length && label !== undefined) {
    const value = payload[0].value.toFixed(valueDecimals);

    return (
      <div className="rounded-lg bg-white p-2 text-brown shadow-lg">
        <div className="text-md flex w-full justify-center border-b-2 border-brown font-sans">
          {formatDayTime(label)}
        </div>
        <div className="mt-2 text-center font-sans">
          {unit === "$" ? `${unit}${value}` : `${value} ${unit}`}
        </div>
      </div>
    );
  }
  return null;
}
